import React from "react";
import QrScanner from "react-qr-scanner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  floorActions,
  homeActions,
  languageActions,
  loaderActions,
  menuActions,
} from "../store";
import { getLocationTextDetails } from "../services/Common";
import Utils from "../utils";

function Scanner() {
  const homeData = useSelector((x) => x.home.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleScan = (data) => {
    if (data?.text) {
      let resultArrayClientName = data.text.split("?");
      let resultArray = resultArrayClientName[1].split("&");
      let locationA = "";
      let locationB = "";
      let qrType = 0;
      let Client = "";
      if (resultArray.length > 0) {
        for (let i = 0; i < resultArray.length; i++) {
          if (resultArray[i].includes("Client")) {
            let split = resultArray[i].split("=");
            if (split.length > 1) {
              Client = split[1];
            }
          }
          if (resultArray[i].includes("Type")) {
            let split = resultArray[i].split("=");
            if (split.length > 1) {
              qrType = parseInt(split[1]);
            }
          }
          if (resultArray[i].includes("POI1")) {
            let split = resultArray[i].split("=");
            if (split.length > 1) {
              locationA = split[1];
            }
          }
          if (resultArray[i].includes("POI2")) {
            let split = resultArray[i].split("=");
            if (split.length > 1) {
              locationB = split[1];
            }
          }
        }
      }
      if (qrType > 0) {
        dispatch(loaderActions.loader(true));
        getLocationTextDetails({
          POI1: locationA,
          POI2: locationB,
          client_name: Client,
        })
          .then((response) => {
            let detail = { ...homeData };

            detail.isInsideCall = true;
            detail.level = response.data.data.level;
            detail.start_level = response.data.data.level;
            if (
              (qrType === 3 || qrType === 4) &&
              response.data.data.location_a
            ) {
              detail.a = response.data.data.location_a;
              if (
                detail.origin &&
                ["3", "4"].indexOf(detail.qr_type.toString()) > -1
              ) {
                detail.origin = detail.origin;
              } else {
                //detail.origin = response.data.data.location_a;
              }
            } else if (qrType === 2) {
              detail.a = response.data.data.location_a;
              if (
                detail.origin &&
                ["3", "4"].indexOf(detail.qr_type.toString()) > -1
              ) {
                detail.origin = detail.origin;
              } else {
                //detail.origin = response.data.data.location_a;
              }
            } else {
              detail.a = response.data.data.location_a;
              detail.b = response.data.data.location_b;
              if (
                detail.origin &&
                ["3", "4"].indexOf(detail.qr_type.toString()) > -1
              ) {
                detail.origin = detail.origin;
              } else {
                //detail.origin = response.data.data.location_a;
              }
            }
            if (detail.a) {
              detail.scanner = true;
            } else {
              detail.scanner = false;
            }
            if (Client) {
              detail.client_name = Client;
            }
            if (qrType) {
              detail.qr_type = qrType;
            }

            dispatch(homeActions.home(detail));
            if (response.data.data.languageList.length > 0) {
              dispatch(
                languageActions.setLang(response.data.data.languageList)
              );
            }
            if (response.data.data.levelDeffination.length > 0) {
              dispatch(
                floorActions.setFloor(response.data.data.levelDeffination)
              );
            }
            dispatch(loaderActions.loader(false));
            navigate("/");
            dispatch(menuActions.setMenu(true));
            if (!handleScan.successToastShown) {
              /*toast.success(
                "Location " +
                  detail.origin +
                  "has been stored in your origin address"
              );*/
              handleScan.successToastShown = true;
            }
          })
          .catch((err) => {
            dispatch(loaderActions.loader(false));
            toast.error(Utils.getErrorMessage(err));
            navigate("/");
          });
      } else {
        navigate("/");
      }
    }
  };

  const handleError = (err) => {
    toast.error(err);
  };
  return (
    <div className={`container`}>
      <div className="row">
        <div className="col-md-12">
          <div className={`my-5 text-center`}>
            <QrScanner
              onScan={handleScan}
              onError={handleError}
              key="environment"
              constraints={{
                audio: false,
                video: { facingMode: "environment" },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scanner;
