import React, {useEffect, useState} from "react";

import useDocumentTitle from "../utils/useDocumentTitle";
import listNavIcon from "../assets/images/list-nav.svg";
import categIcon from "../assets/images/categ-icon.svg";
import favIcon from "../assets/images/Star1.svg";
import mapIcon from "../assets/images/map.svg";
import Location from "../components/Location";
import Category from "../components/Category";
import Map from "../components/Map";
import Header from "./partials/Header";
import Favourite from "./Favourite";
import Utils from "../utils";
import {useDispatch, useSelector} from "react-redux";
import {
    HelpActions,
    floorActions,
    homeActions,
    infoAction,
    languageActions,
    loaderActions,
    tabActions,
} from "../store";
import settingIcon from "../assets/images/settings-icon.svg";
import qrIcon from "../assets/images/qr-icon.svg";
import originIcon from "../assets/images/origin.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {getLocationTextDetails} from "../services/Common";
import {toast} from "react-toastify";

function Home() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const qrType = queryParams.get("Type");
    const POI1 = queryParams.get("POI1");
    const POI2 = queryParams.get("POI2");
    const Client = queryParams.get("Client");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const homeData = useSelector((x) => x.home.value);
    const tabData = useSelector((x) => x.tab.value);
    const helpData = useSelector((x) => x.help.value);


    const [tab, setTab] = useState(tabData.tab);
    const [favShow, setFavShow] = useState(false);

    useDocumentTitle(`Home`);
    useEffect(() => {
        let value = Utils.getLocalCookie();
        if (value.length > 0) {
            setFavShow(true);
        }
    }, []);

    useEffect(() => {
        if (Client) {
            dispatch(loaderActions.loader(true));
            getLocationTextDetails({POI1: POI1, POI2: POI2, client_name: Client})
                .then((response) => {
                    let detail = {...homeData};
                    detail.isInsideCall = false;

                    detail.level = response.data.data.level;
                    detail.start_level = response.data.data.level;
                    detail.clientData = response.data.data.clientData;
                    detail.getAboutInfo = response.data.data.getAboutInfo;
                    //detail.origin = response.data.data.location_a;

                    let SetOrigin = localStorage.getItem("SetOrigin");
                    if (
                        (qrType == 3 || qrType == 4 || qrType == 2) &&
                        response.data.data.location_a
                    ) {
                        detail.a = response.data.data.location_a;
                        if (SetOrigin == null) {
                            detail.origin = response.data.data.location_a;
                            localStorage.setItem("SetOrigin", response.data.data.location_a);
                        } else {
                            if (qrType == 3 || qrType == 4) {
                                detail.origin = response.data.data.location_a;
                                localStorage.setItem("SetOrigin", response.data.data.location_a);
                            } else {
                                detail.origin = SetOrigin;
                            }
                        }
                        detail.b = "";
                    } else {
                        if (response.data.data.location_a) {
                            detail.a = response.data.data.location_a;
                        }
                        if (response.data.data.location_b) {
                            detail.b = response.data.data.location_b;
                        }
                        if (response.data.data.location_a) {
                            if (SetOrigin == null) {
                                detail.origin = response.data.data.location_a;
                                localStorage.setItem("SetOrigin", response.data.data.location_a);
                            } else {
                                if (qrType == 3 || qrType == 4) {
                                    detail.origin = response.data.data.location_a;
                                    localStorage.setItem("SetOrigin", response.data.data.location_a);
                                } else {
                                    detail.origin = SetOrigin;
                                }
                            }
                        }
                    }
                    if (detail.a) {
                        detail.scanner = true;
                    } else {
                        detail.scanner = false;
                    }
                    if (Client) {
                        detail.client_name = Client;
                    }
                    if (qrType) {
                        detail.qr_type = qrType;
                    }


                    dispatch(homeActions.home(detail));
                    if (response.data.data.languageList.length > 0) {
                        dispatch(languageActions.setLang(response.data.data.languageList));
                    }
                    if (response.data.data.levelDeffination.length > 0) {
                        dispatch(
                            floorActions.setFloor(response.data.data.levelDeffination)
                        );
                    }

                    let hData = {...helpData};
                    hData.getInfo = response.data.data.getInfo;
                    hData.getHelp = response.data.data.getHelp;
                    dispatch(HelpActions.setHelp(hData));

                    dispatch(loaderActions.loader(false));
                    navigate("/");
                    dispatch(infoAction.setInfo(true));
                    /*toast.success(
                      "Location " +
                        response.data.data.location_a +
                        "has been stored in your origin address"
                    );*/
                })
                .catch((err) => {
                    dispatch(loaderActions.loader(false));
                    toast.error(Utils.getErrorMessage(err));
                    navigate("/");
                });
        } else if (!homeData.client_name) {
            toast.error("Client is required.");
        }
    }, [Client]);

    useEffect(() => {
        setTab(tabData.tab);
    }, [tabData]);

    const handleTab = (e, index) => {
        e.preventDefault();
        let data = {...homeData};
        data.scanner = false;
        data.main_category = false;
        if (index === 2 || index === 4) {
            data.search_area = false;
            if (index === 2) {
                data.main_category = true;
            }
        } else {
            data.search_area = true;
        }
        dispatch(homeActions.home(data));
        dispatch(tabActions.home({tab: index}));
    };
    /* const handelChangeFloorHeader = (e) => {
           let mapElement = document.querySelector('#map-floor');
           let element = document.getElementById('map-show-flow');
           if (mapElement) {
               mapElement.value = e.target.value;
               mapElement.dispatchEvent(new Event('change'));
               element.click();
           }
       }*/

    const handelOrigin = () => {
        let data = {...homeData};
        if (data.origin) {
            data.b = data.origin;
            data.scanner = true;
            dispatch(homeActions.home(data));

            let element = document.getElementById("frmSearch");
            element.click();
        }


    };

    return (
        <main>
            <Header/>
            <section className="tab-pane-section pb-0">
                <div className="container">
                    <div className="tabs-nav-section">
                        <ul className="tabs">
                            {/*<li className="click-map"><a href="!#" onClick={(e) => {
                                e.preventDefault();
                            }}>Click on the map to select A and B</a></li>*/}
                            <li
                                className="click-map"
                                role={`button`}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#sidebar-offcanvas"
                            >
                                <img src={settingIcon} alt="settings" width={25}/>
                            </li>
                            <li
                                className={`tab-link ${tab === 4 ? `current` : ``}`}
                                onClick={(e) => handleTab(e, 4)}
                            >
                                <a href={"!#"} id={`tab_4`} onClick={(e) => handleTab(e, 4)}>
                                    <i className="icon">
                                        <img src={mapIcon} alt="map"/>
                                    </i>
                                    Map
                                </a>
                            </li>

                            <li
                                id={`tab_1_link`}
                                className={`tab-link ${tab === 1 ? `current` : ``}`}
                                onClick={(e) => handleTab(e, 1)}
                            >
                                <a href={"!#"} id={`tab_1`} onClick={(e) => handleTab(e, 1)}>
                                    <i className="icon">
                                        <img src={listNavIcon} alt="menu"/>
                                    </i>
                                    List
                                </a>
                            </li>
                            <li
                                className={`tab-link ${tab === 2 ? `current` : ``}`}
                                onClick={(e) => handleTab(e, 2)}
                            >
                                <a href={"!#"} id={`tab_2`} onClick={(e) => handleTab(e, 2)}>
                                    <i className="icon">
                                        <img src={categIcon} alt="menu"/>
                                    </i>
                                    Categories
                                </a>
                            </li>
                            {favShow && (
                                <li
                                    className={`tab-link ${tab === 3 ? `current` : ``}`}
                                    onClick={(e) => handleTab(e, 3)}
                                >
                                    <a href={"!#"} id={`tab_3`} onClick={(e) => handleTab(e, 3)}>
                                        <i className="icon">
                                            <img src={favIcon} alt="menu"/>
                                        </i>
                                        Favourites
                                    </a>
                                </li>
                            )}
                            <li
                                className="qr-icon click-map"
                                role={`button`}
                                onClick={() => {
                                    navigate("/scanner");
                                }}
                            >
                                <img src={qrIcon} alt="qr-code" width={25}/>
                            </li>

                            <li className="car-icon click-map" onClick={handelOrigin}>
                                <img src={originIcon} alt="Origin" width={25}/>
                            </li>

                            {/*<li className="plus-minus">
                                 <span className="input-group-btn">
                                  <button type="button" className="btn btn-default btn-number"
                                          onClick={increaseMapFloor}>
                                      <i className="fa fa-plus" aria-hidden="true"></i>
                                  </button>
                              </span>
                            </li>
                            <li className="addd-select">
                                <select className="form-select col-md-2 map-floor" id={`header-map-floor`}
                                        onChange={handelChangeFloorHeader}>
                                    <option value="" data-level={0}>Select floor</option>
                                    <option value="garageFloor" data-level={1}>Garage Floor</option>
                                    <option value="groundFloor" data-level={2}>Ground Floor</option>
                                    <option value="firstFloor" data-level={3}>First Floor</option>
                                    <option value="secondFloor" data-level={4}>Second Floor</option>
                                    <option value="thirdFloor" data-level={5}>Third Floor</option>
                                </select>
                            </li>
                            <li className="plus-link">
                               <span className="input-group-btn">
                                  <button type="button" className="btn btn-default btn-number"
                                          onClick={decreaseMapFloor}>
                                   <i className="fa fa-minus" aria-hidden="true"></i>
                                  </button>
                              </span>
                            </li>*/}
                        </ul>
                    </div>

                    <div className="tabs-content">
                        <div
                            id="tab-1"
                            className={`tab-content ${tab === 1 ? `current` : ``}`}
                        >
                            <Location/>
                        </div>
                        <div
                            id="tab-2"
                            className={`tab-content ${tab === 2 ? `current` : ``}`}
                        >
                            <Category/>
                        </div>
                        {favShow && (
                            <div
                                id="tab-3"
                                className={`tab-content ${tab === 3 ? `current` : ``}`}
                            >
                                <Favourite/>
                            </div>
                        )}
                        <div
                            id="tab-4"
                            className={`tab-content ${tab === 4 ? `current` : ``}`}
                        >
                            <Map homeData={homeData}/>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Home;
