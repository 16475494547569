import axiosInstance from "./AxiosInstance";

export function getCategoryList(data) {
    return axiosInstance.post(`category-list`, data);
}

export function getLocationList(data) {
    return axiosInstance.post(`location-list`, data);
}

export function getPOIListByCategory(data) {
    return axiosInstance.post(`category-location-list`, data);
}

export function getLocationDetails(data) {
    return axiosInstance.post(`location-detail`, data);
}

export function getLocationFavList(data) {
    return axiosInstance.post(`favourite-list`, data);
}

export function getPOIpathListSearch(data) {
    return axiosInstance.post(`poi-path-list-search`, data);
}

export function getLocationTextDetails(data) {
    return axiosInstance.post(`poi-path-list-name`, data);
}

export function getClickedPoi(data) {
    return axiosInstance.post(`get-clicked-poi`, data);
}

export function getLocationName(data) {
    return axiosInstance.post(`check-poi-name`, data);
}

export function getFAVPoi(data) {
    return axiosInstance.post(`get-fev-poi`, data);
}

export function getAllPOI(data) {
    return axiosInstance.post(`get-all-poi`, data);
}
export function getLanguageList(data) {
    return axiosInstance.get(`language-list`,data);
}

