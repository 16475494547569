import React, { useEffect, useState } from "react";
import { getCategoryList, getPOIListByCategory } from "../services/Common";
import { toast } from "react-toastify";
import Utils from "../utils";
import { NavLink } from "react-router-dom";
import { homeActions, tabActions } from "../store";
import { useDispatch, useSelector } from "react-redux";
import infoIcon from "../assets/images/question_mark.png";
import mapLine from "../assets/images/map-line.svg";

function Category() {
  const dispatch = useDispatch();
  const homeData = useSelector((x) => x.home.value);
  const helpData = useSelector((x) => x.help.value);

  const [categoryList, setCategoryList] = useState([]);
  const [locationCatList, setLocationCatList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLocationListing, setIsLocationListing] = useState(false);
  const [params, setParams] = useState({ parent_id: 0, search: "" });
  const [categoryName, setCategoryName] = useState("Select Main Categories");
  const [isArrow, setIsArrow] = useState(false);

  /*const infoModalRef = useRef(null);
    const infoCloseModalRef = useRef(null);*/
  const [advertiseImage, setAdvertiseImage] = useState("");
  const [advertiseUuid, setAdvertiseUuid] = useState("");
  useEffect(
    (e) => {
      if (homeData?.client_name) {
        setIsLocationListing(!homeData.main_category);
        setIsLoading(true);
        getCategoryList(params)
          .then((response) => {
            setCategoryList(response.data.data);
            if (
              response.data.advertisementMain &&
              response.data.advertisementMain?.poi_image &&
              params.parent_id === 0
            ) {
              setAdvertiseImage(response.data.advertisementMain.poi_image);
              setAdvertiseUuid(response.data.advertisementMain.poi_uuid);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [params, homeData?.main_category, homeData?.client_name]
  );

  const handleCategory = (e, category) => {
    e.preventDefault();
    if (parseInt(category.is_sub) > 0) {
      setParams({ parent_id: category.id });
      setCategoryName("Select - " + category.name + " - Subcategories");
      if (category?.advertise_image) {
        setAdvertiseImage(category.advertise_image);
        setAdvertiseUuid(category.advertise_uuid);
      } else {
        setAdvertiseImage("");
        setAdvertiseUuid("");
      }
      setIsArrow(true);
    } else {
      getPOIListByCategory({
        category_id: category.id,
        parent_category: category.parent_id,
      })
        .then((response) => {
          setLocationCatList(response.data.data);
          setIsLocationListing(true);
          let data = { ...homeData };
          data.search_area = true;
          dispatch(homeActions.home(data));
          //infoModalRef?.current.click();
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };
  const handleCategoryBack = (e) => {
    e.preventDefault();
    let parent_id = 0;
    if (isLocationListing && params.parent_id) {
      parent_id = params.parent_id;
    }
    if (parent_id === 0) {
      setIsArrow(false);
      setCategoryName("Select Main Categories");
    }
    setIsLocationListing(false);
    let data = { ...homeData };
    data.search_area = false;
    dispatch(homeActions.home(data));
    setParams({ parent_id: parent_id, search: "" });
  };
  /* const handleModelClose = (e) => {
         infoCloseModalRef?.current.click();
     }*/
  const handleLocationB = (e, item) => {
    e.preventDefault();
    let data = { ...homeData };
    let isCheckInput = false;
    let input = sessionStorage.getItem("input");
    if (item.name === data.b && input === "A") {
      toast.error("A can not same with B.Please select another");
    } else if (item.name === data.a && input === "B") {
      toast.error("B can not same with A.Please select another");
    } else {
      if (input) {
        if (input === "A") {
          data.a = item.name;
          data.start_level = item.level;
          data.info_raw = "Select Start (A)";
          isCheckInput = true;
        } else if (input === "B") {
          data.b = item.name;
          data.info_raw = "Select Destination (B)";
          isCheckInput = true;
        }
      }
      if (!isCheckInput) {
        if (!data.a) {
          data.a = item.name;
          data.start_level = item.level;
        } else {
          data.b = item.name;
        }
      }
    }
    dispatch(homeActions.home(data));
  };
  const drawRouteList = (e) => {
    e.preventDefault();
    dispatch(tabActions.home({ tab: 4 }));
  };
  return (
    <>
      <div className="container-inner">
        {advertiseImage && advertiseUuid && !isLocationListing && (
          <>
            <div className="inner-container">
              <div className={`float-end`} style={{ margin: "5px 5px 0 0" }}>
                {`Advertisement`}
              </div>
            </div>
            <div className="vipsss-lideshow-bg">
              <img src={advertiseImage} alt="vipss1" />
              <div className="view-btn  mb-2">
                <div className="col-table-3">
                  <NavLink
                    className="icon-images"
                    to={`/location/${advertiseUuid}`}
                  >
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      style={{
                        color: "white",
                        fontSize: "25px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="container-categories cate-title">
          {isLocationListing && (
            <>
              <div className="select-block">
                {/* <div className="info-icon">
                  <h3>
                    <i
                      className="fa fa-arrow-left icon-back"
                      aria-hidden="true"
                      onClick={handleCategoryBack}
                    ></i>{" "}
                    &nbsp;&nbsp;&nbsp;
                    <span
                      className="info-icon"
                      role={`button`}
                      data-bs-toggle="offcanvas"
                      data-bs-target="#sidebar-category"
                    >
                      <img
                        src={infoIcon}
                        alt={`...`}
                        style={{ width: "16px", marginLeft:"10px" }}
                      />
                    </span>
                    &nbsp;{homeData?.info_raw}
                  </h3>
                </div> */}

                <div>
                  <h3>
                    {isArrow && (
                      <i
                        className="fa fa-arrow-left icon-back"
                        aria-hidden="true"
                        onClick={handleCategoryBack}
                      ></i>
                    )}
                    <span
                      className="info-icon"
                      role={`button`}
                      data-bs-toggle="offcanvas"
                      data-bs-target="#sidebar-category"
                    >
                      <img
                        src={infoIcon}
                        alt={`...`}
                        style={{ width: "16px", marginLeft: "10px" }}
                      />
                    </span>
                    &nbsp;&nbsp; &nbsp;{homeData?.info_raw}
                  </h3>
                </div>

                {homeData.a && homeData.b && homeData.a !== homeData.b && (
                  <div>
                    <button
                      type={`button`}
                      onClick={drawRouteList}
                      className="btnroute float-end"
                    >
                      <i className="icon-ro">
                        <img src={mapLine} alt={`...`} />
                      </i>
                      <span>Show Route</span>
                    </button>
                    {homeData.meter_value && (
                      <span className={`float-end meterValue`}>
                        {homeData.meter_value}{" "}
                        {homeData?.clientData?.show_meter_or_feet}{" "}
                        {homeData.min_value}{" "}
                        {
                          homeData?.clientData
                            ?.dominent_criteria_path_time_distance
                        }
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className={`list-view`}>
                {locationCatList &&
                  locationCatList.map((item, index) => (
                    <div
                      className="tab-content-table cursor-pointer"
                      key={index}
                    >
                      <div className="col-table-6 notranslate">
                        <h4 onClick={(e) => handleLocationB(e, item)}>
                          {item.name}
                        </h4>
                      </div>
                      <div className="col-table-3">
                        <div className="logo-images">
                          <img width="106" src={item.image} alt={item.name} />
                        </div>
                      </div>
                      <div className="col-table-3">
                        <NavLink
                          className="icon-images"
                          to={`/location/${item.uuid}`}
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </NavLink>
                      </div>
                    </div>
                  ))}
                {locationCatList.length === 0 && (
                  <div className="tab-content-table">
                    <div className="notranslate">
                      <p>No records found</p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {!isLocationListing && (
            <>
              <div className="border-bootom">
                <h3>
                  {isArrow && (
                    <i
                      className="fa fa-arrow-left icon-back"
                      aria-hidden="true"
                      onClick={handleCategoryBack}
                    ></i>
                  )}
                  <span
                    className="info-icon"
                    role={`button`}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#sidebar-category"
                  >
                    <img
                      src={infoIcon}
                      alt={`...`}
                      style={{ width: "16px", marginLeft: "10px" }}
                    />
                  </span>
                  &nbsp;&nbsp; &nbsp;{categoryName}
                </h3>
              </div>
              <div className="container-categories-nner">
                <div className="row">
                  {isLoading &&
                    Array.from(Array(6)).map((_, index) => (
                      <div className="col-4 col-sm-6 col-xl-4" key={index}>
                        <div className="categories-lest is-loading" />
                      </div>
                    ))}

                  {!isLoading &&
                    categoryList.map((category, index) => (
                      <div className="col-4 col-sm-6 col-xl-4" key={index}>
                        <div
                          className={` cursor-pointer ${
                            parseInt(category.is_sub) > 0 ||
                            parseInt(category.parent_id) === 0
                              ? `categories-lest`
                              : `subcategories-block`
                          } active`}
                          onClick={(e) => handleCategory(e, category)}
                        >
                          <i className="icon-img">
                            <img src={category.image} alt="hanger" />
                          </i>
                          <div className="description">{category.name}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="offcanvas offcanvas-start sidebar-help"
        tabIndex="-1"
        id={`sidebar-category`}
        aria-labelledby="offcanvasExampleLabelCategory"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <div className="d-flex flex-column h-100">
            <div className="container">
              {helpData.getHelp?.map((item, index) => (
                <React.Fragment key={index}>
                  {item.help_identifier === "category" && (
                    <div className="row mb-4" key={index}>
                      {item?.help_text && (
                        <div className="col-9">{item.help_text}</div>
                      )}
                      <div className="col-3 text-end">
                        {item?.help_image && (
                          <img
                            src={item?.help_image}
                            alt={`...`}
                            style={{ width: "35px" }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
