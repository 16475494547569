import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Utils from "../utils";
import {getClickedPoi, getPOIpathListSearch} from "../services/Common";
import {HelpActions, homeActions, infoAction, store} from "../store";
import {useDispatch, useSelector} from "react-redux";
import configDb from "../config";
import infoIcon from "../assets/images/question_mark.png";
import AIcon from "../assets/images/A.png";
import mapLine from "../assets/images/map-line.svg";
import {RouteFlagEnum} from "../utils/enum";

function Map({homeData}) {
    const tabData = useSelector((x) => x.tab.value);
    const floorData = useSelector((x) => x.floor.value);

    const info = useSelector((x) => x.info.value);
    const helpData = useSelector((x) => x.help.value);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [floor, setFloor] = useState("groundFloor");
    const [floorNumber, setFloorNumber] = useState(2);
    const [floorText, setFloorText] = useState("Ground Floor");
    const [dummyFloor, setDummyFloor] = useState("");
    const [meterValue, setMeterValue] = useState("");
    // eslint-disable-next-line
    const [minValue, setMinValue] = useState("");
    const [nextFloorName, setNextFloorName] = useState("");
    const [allFloorList, setAllFloorList] = useState([]);
    const [pathLevelList, setPathLevelList] = useState([]);
    const [pathCount, setPathCount] = useState(0);
    const [pathDrops, setPathDrops] = useState([]);
    const [isInfoData, setIsInfoData] = useState(true);
    const [iZoom, setIZoom] = useState(2);
    const [FeetValue, setFeetValue] = useState({});

    const [routeDisplayFlag, SetRouteDisplayFlag] = useState(
        RouteFlagEnum.NOT_SHOW
    );

    const sideBarPopupOpen = useRef(null);

    const ZoomLevels = Utils.detectDevice();

    // eslint-disable-next-line
    useEffect(() => {
        let settingOpened = localStorage.getItem("settingOpened");
        if (settingOpened && parseInt(settingOpened) === 1) {
            if (info && isInfoData && homeData.qr_type) {
                sideBarPopupOpen?.current?.click();
                setIsInfoData(false);
                let data = {...helpData};
                data.isInfo = true;
                dispatch(HelpActions.setHelp(data));
                dispatch(infoAction.setInfo(false));
            }
        }
    });

    useEffect(() => {
        if (homeData.meter_value) {
            setMeterValue(homeData.meter_value);
        }
        if (homeData.min_value) {
            setMinValue(Math.ceil(homeData.min_value));
        }
    }, [homeData]);

    useEffect(() => {
        setAllFloorList(floorData);
    }, [floorData]);

    useEffect(() => {
        if (homeData?.level) {
            let list = [...floorData];
            let index = list.findIndex(
                (x) => parseInt(x.floor) === parseInt(homeData.level)
            );
            if (index > -1) {
                setFloor(list[index]["id"]);
                setFloorNumber(parseInt(list[index]["floor"]));
                setFloorText(list[index]["name"]);
            }
        }
    }, [homeData?.level, floorData]);

    useEffect(() => {
        if (tabData.tab === 4 && homeData?.client_name) {
            setTimeout(function () {
                let mapElement = document.querySelector("#map-floor");
                if (mapElement) {
                    let getSVGName = Utils.getLevelName(
                        mapElement.dataset.level,
                        floorData
                    );
                    setFloorNumber(mapElement.dataset.level);
                    let mapElementLi = document.getElementById("li-" + getSVGName);
                    if (mapElementLi) {
                        mapElementLi.click();
                    }
                }
            }, 700);
        }
    }, [tabData?.tab, homeData?.client_name, floorData]);

    useEffect(() => {
        if (tabData?.tab === 4) {
            if (homeData?.a && homeData?.b) {
                let element = document.getElementById("searchMap");
                element.click();
            }
        }
    }, [homeData?.a, homeData?.b, tabData?.tab]);

    const handelChangeFloor = (e, item) => {
        e.preventDefault();
        let value = item.id;
        setFloor(value);
        setFloorText(item.name);
        setFloorNumber(item.floor);
        if (document.querySelector(".mallLayout")) {
            document.querySelector(".mallLayout").classList.add("zoom-svg-out");
        }
        if (value !== dummyFloor) {
            setDummyFloor(value);
            setTimeout(function () {
                document.querySelectorAll(".lineSingh").forEach((el) => el.remove());
                document.querySelectorAll("#start-point").forEach((el) => el.remove());
                document.querySelectorAll("#end-point").forEach((el) => el.remove());

                let level = item.floor;
                if (level === 1) {
                    document.getElementById("decrease-map").classList.add("d-none");
                } else {
                    document.getElementById("decrease-map").classList.remove("d-none");
                }
                if (level === 5) {
                    document.getElementById("increase-map").classList.add("d-none");
                } else {
                    document.getElementById("increase-map").classList.remove("d-none");
                }
                let data = {...homeData};
                data.level = level;

                let svgVal = value;
                if (ZoomLevels) {
                    setIZoom(2);
                    value = value + "_2";
                }

                let fileName = Utils.getFloorImage(value);
                fetch(fileName)
                    .then((response) => {
                        if (!response.ok) {
                            toast.error("Network response was not ok");
                        }
                        return response.text();
                    })
                    .then((svgContent) => {
                        if (document.querySelector(".mallLayout")) {
                            document
                                .querySelector(".mallLayout")
                                .classList.remove("zoom-svg-out");
                            // setTimeout(function () {
                            //   document
                            //     .querySelector(".mallLayout")
                            //     .classList.add(`zoom-svg-in`);
                            // }, 500);
                            // let mapElement = document.querySelector("#map-floor");
                            // mapElement.dataset.value = 2;
                            document.getElementById("zoom-out").classList.remove("d-none");
                        }
                        Utils.svgContainer(svgContent);
                        if (ZoomLevels) {
                            document
                                .getElementById(`${svgVal}`)
                                .addEventListener("click", (e) => mallLayout(e, 2));
                        } else {
                            document
                                .getElementById(svgVal)
                                .addEventListener("click", mallLayout);
                        }
                        Utils.setAllPOI(
                            level,
                            data.a,
                            data.b,
                            data.qr_type,
                            data.origin,
                            data.isInsideCall,
                            floorData
                        );
                    })
                    .catch((error) => {
                        toast.error(Utils.getErrorMessage(error));
                    });

                dispatch(homeActions.home(data));
                setTimeout(function () {
                    if (Utils.getLocalCookie().length > 0) {
                        Utils.setFaviouriteIcon(level, floorData);
                    }
                }, 500);
                setTimeout(function () {
                    if (document.querySelector("#end-point")) {
                        document.querySelector("#end-point").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                        });
                    } else if (document.querySelector("#start-point")) {
                        document.querySelector("#start-point").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                        });
                    }
                }, 3000);
            }, 1000);
        } else {
            // document.getElementById('showMapContainer').innerHTML = '';
        }
    };

    const mallLayout = (svgContent, level1 = 1) => {
        const svg = svgContent.currentTarget;
        const pt = svg.createSVGPoint();
        pt.x = svgContent.clientX;
        pt.y = svgContent.clientY;
        const cursorPoint = pt.matrixTransform(svg.getScreenCTM().inverse());

        /*const event = svgContent;
        const svg = event.currentTarget;
        const pt = svg.createSVGPoint();

        let clientX, clientY;

        if (event.touches && event.touches.length) {
          clientX = event.touches[0].clientX;
          clientY = event.touches[0].clientY;
        } else {
          clientX = event.clientX;
          clientY = event.clientY;
        }

        pt.x = clientX;
        pt.y = clientY;

        const cursorPoint = pt.matrixTransform(svg.getScreenCTM().inverse());
        */

        let level = store.getState().home.value.level;
        let zoomedX = "";
        let zoomedY = "";
        if (level1 == 1) {
            zoomedX = cursorPoint.x;
            zoomedY = cursorPoint.y;
        } else {
            zoomedX = cursorPoint.x * parseInt(level1);
            zoomedY = cursorPoint.y * parseInt(level1);
        }
        cursorPoint.x = zoomedX;
        cursorPoint.y = zoomedY;

        let pointerName = svgContent.srcElement.innerHTML;
        if (
            svgContent.target.attributes?.name &&
            svgContent.target.attributes.name.textContent
        ) {
            pointerName = svgContent.target.attributes.name.textContent;
        }
        let obj = {
            clicked_x_cordinate: cursorPoint.x,
            clicked_y_cordinate: cursorPoint.y,
            clicked_level: level,
            Client: homeData?.client_name,
            pointer_name: pointerName,
            origin: homeData.a,
        };
        getClickedPoi(obj)
            .then((response) => {
                if (response.data.clickedPoi.length > 0) {
                    navigate(`/location/${response.data.clickedPoi[0].uuid}`);
                }
            })
            .catch((error) => {
                console.error("Error fetching clickedPoi:", error);
            });
    };

    const onZoomOut = () => {
        if (ZoomLevels) {
            if (iZoom === 1) {
                setIZoom(2);
                let fileName = Utils.getFloorImage(`${floor}_2`);
                let newData = {...homeData};

                fetch(fileName)
                    .then((response) => {
                        if (!response.ok) {
                            toast.error("Network response was not ok");
                        }
                        return response.text();
                    })
                    .then((svgContent) => {
                        if (document.querySelector(".mallLayout")) {
                            document
                                .querySelector(".mallLayout")
                                .classList.remove("zoom-svg-out");
                        }
                        Utils.svgContainer(svgContent);
                        document
                            .getElementById(`${floor}`)
                            .addEventListener("click", (e) => mallLayout(e, 2));
                        Utils.setAllPOI(
                            newData.level,
                            newData.a,
                            newData.b,
                            newData.qr_type,
                            newData.origin,
                            newData.isInsideCall,
                            floorData
                        );
                        if (routeDisplayFlag == RouteFlagEnum.SHOW_ROUTE) {
                            Utils.drawPathForLevel(
                                newData.start_level,
                                pathCount,
                                pathDrops,
                                true,
                                floorData
                            );
                            Utils.drowRouteDots();
                        }
                        if (routeDisplayFlag == RouteFlagEnum.FINISH_ROUTE) {
                            finishMap();
                        }
                        dispatch(homeActions.home(newData));
                    })
                    .catch((error) => {
                        toast.error(Utils.getErrorMessage(error));
                    });
            } else if (iZoom === 2) {
                setIZoom(3);
                let data = {...homeData};
                document.getElementById("zoom-out").classList.add("d-none");
                let fileName = Utils.getFloorImage(`${floor}_4`);
                let newData = {...homeData};

                fetch(fileName)
                    .then((response) => {
                        if (!response.ok) {
                            toast.error("Network response was not ok");
                        }
                        return response.text();
                    })
                    .then((svgContent) => {
                        if (document.querySelector(".mallLayout")) {
                            document
                                .querySelector(".mallLayout")
                                .classList.remove("zoom-svg-out");
                            // setTimeout(function () {
                            //   document
                            //     .querySelector(".mallLayout")
                            //     .classList.add(`zoom-svg-in`);
                            // }, 500);
                        }
                        Utils.svgContainer(svgContent);
                        document
                            .getElementById(`${floor}`)
                            .addEventListener("click", (e) => mallLayout(e, 4));
                        Utils.setAllPOI(
                            newData.level,
                            newData.a,
                            newData.b,
                            newData.qr_type,
                            newData.origin,
                            newData.isInsideCall,
                            floorData
                        );
                        if (routeDisplayFlag == RouteFlagEnum.SHOW_ROUTE) {
                            Utils.drawPathForLevel(
                                newData.start_level,
                                pathCount,
                                pathDrops,
                                true,
                                floorData
                            );
                            Utils.drowRouteDots();
                        }
                        if (routeDisplayFlag == RouteFlagEnum.FINISH_ROUTE) {
                            finishMap();
                        }
                        dispatch(homeActions.home(newData));
                    })
                    .catch((error) => {
                        toast.error(Utils.getErrorMessage(error));
                    });
            }
        } else {
            let zoom = document.querySelector("svg").style.getPropertyValue("zoom");

            if (!zoom) {
                zoom = 0.5;
            }

            zoom = parseFloat(zoom) - 0.2;

            if (zoom < 0.3) {
                document.getElementById("zoom-out").classList.add("d-none");
            }

            document.querySelector("svg").style.zoom = zoom;

            setTimeout(function () {
                if (document.querySelector("#start-point")) {
                    document.querySelector("#start-point").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                    });
                } else if (document.querySelector("#end-point")) {
                    document.querySelector("#end-point").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                    });
                }
            }, 500);
        }
    };

    const onZoomIn = () => {
        if (ZoomLevels) {
            if (iZoom === 3) {
                setIZoom(2);
                document.getElementById("zoom-out").classList.remove("d-none");
                let fileName = Utils.getFloorImage(`${floor}_2`);
                let newData = {...homeData};
                fetch(fileName)
                    .then((response) => {
                        if (!response.ok) {
                            toast.error("Network response was not ok");
                        }
                        return response.text();
                    })
                    .then((svgContent) => {
                        if (document.querySelector(".mallLayout")) {
                            document
                                .querySelector(".mallLayout")
                                .classList.remove("zoom-svg-out");
                        }
                        Utils.svgContainer(svgContent);
                        document
                            .getElementById(`${floor}`)
                            .addEventListener("click", (e) => mallLayout(e, 2));
                        Utils.setAllPOI(
                            newData.level,
                            newData.a,
                            newData.b,
                            newData.qr_type,
                            newData.origin,
                            newData.isInsideCall,
                            floorData
                        );
                        if (routeDisplayFlag == RouteFlagEnum.SHOW_ROUTE) {
                            Utils.drawPathForLevel(
                                newData.start_level,
                                pathCount,
                                pathDrops,
                                true,
                                floorData
                            );

                            Utils.drowRouteDots();
                        }
                        if (routeDisplayFlag == RouteFlagEnum.FINISH_ROUTE) {
                            finishMap();
                        }
                        dispatch(homeActions.home(newData));
                    })
                    .catch((error) => {
                        toast.error(Utils.getErrorMessage(error));
                    });
            } else if (iZoom === 2) {
                setIZoom(1);
                document.getElementById("zoom-out").classList.remove("d-none");
                let fileName = Utils.getFloorImage(`${floor}`);
                let newData = {...homeData};
                fetch(fileName)
                    .then((response) => {
                        if (!response.ok) {
                            toast.error("Network response was not ok");
                        }
                        return response.text();
                    })
                    .then((svgContent) => {
                        if (document.querySelector(".mallLayout")) {
                            document
                                .querySelector(".mallLayout")
                                .classList.remove("zoom-svg-out");
                        }
                        Utils.svgContainer(svgContent);
                        document
                            .getElementById(`${floor}`)
                            .addEventListener("click", (e) => mallLayout(e));
                        Utils.setAllPOI(
                            newData.level,
                            newData.a,
                            newData.b,
                            newData.qr_type,
                            newData.origin,
                            newData.isInsideCall,
                            floorData
                        );
                        if (routeDisplayFlag == RouteFlagEnum.SHOW_ROUTE) {
                            Utils.drawPathForLevel(
                                newData.start_level,
                                pathCount,
                                pathDrops,
                                true,
                                floorData
                            );

                            Utils.drowRouteDots();
                        }
                        if (routeDisplayFlag == RouteFlagEnum.FINISH_ROUTE) {
                            finishMap();
                        }
                        dispatch(homeActions.home(newData));
                    })
                    .catch((error) => {
                        toast.error(Utils.getErrorMessage(error));
                    });
            }
        } else {
            document.getElementById("zoom-out").classList.remove("d-none");
            let zoom = document.querySelector("svg").style.getPropertyValue("zoom");
            if (!zoom) {
                zoom = 0.5;
            }
            zoom = parseFloat(zoom) + 0.2;
            document.querySelector("svg").style.zoom = zoom;
            setTimeout(function () {
                if (document.querySelector("#start-point")) {
                    document.querySelector("#start-point").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                    });
                } else if (document.querySelector("#end-point")) {
                    document.querySelector("#end-point").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                    });
                }
            }, 500);
        }
    };

    const handleFindMap = (e) => {
        e.preventDefault();
        let dataList = {...homeData};
        setPathCount(0);
        setPathDrops([]);
        Utils.setMapContinue(0);
        let isMobility = localStorage.getItem(configDb.data.is_mobility);
        if (!isMobility) {
            isMobility = false;
        }
        let obj = {from: dataList.a, to: dataList.b, is_mobility: isMobility};
        if (dataList.a === dataList.b) {
            toast.warning("Location A & B both are same");
        } else {
            Utils.svgContainer("");
            getPOIpathListSearch(obj)
                .then((response) => {
                    Utils.setStoreStorage(obj);
                    let drops = response.data.pathDrops;
                    let pathDropsCount = response.data.pathDropsCount;
                    setPathDrops(drops);
                    setPathCount(pathDropsCount);
                    setFeetValue(response.data.data);
                    if (pathDropsCount > 0) {
                        let list = [];
                        for (let pc = 1; pc <= pathDropsCount; pc++) {
                            if (drops[pc] !== undefined) {
                                if (drops[pc] && drops[pc]["L" + pc]) {
                                    let levelPath = drops[pc]["L" + pc];
                                    if (list.indexOf(levelPath) === -1) {
                                        list.push(levelPath);
                                    }
                                }
                            }
                        }
                        if (list.length > 0) {
                            let data = {...homeData};

                            if (response.data.data?.time_in_minutes) {
                                data.min_value = response.data.data.time_in_minutes;
                            }
                            if (response.data.data?.distance_in_meters) {
                                data.meter_value = response.data.data.distance_in_meters;
                            } else if (response.data.data?.distance_in_feet) {
                                data.meter_value = response.data.data.distance_in_feet;
                            }
                            loadSVG(list[0], pathDropsCount, drops);

                            data.scanner = false;
                            data.level = list[0];
                            data.start_level = list[0];
                            dispatch(homeActions.home(data));

                            //if (list.length > 1) {
                            list = list.slice(1);
                            //Utils.setMapContinue(1);
                            //}
                        }
                        setPathLevelList(list);
                        setTimeout(function () {
                            if (document.querySelector("#start-point")) {
                                document.querySelector("#start-point").scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                    inline: "center",
                                });
                            }
                        }, 3000);
                    } else {
                        toast.error("Oops...something went wrong. Please try again.");
                    }
                })
                .catch((err) => {
                    toast.error(Utils.getErrorMessage(err));
                });
        }
    };

    const loadSVG = (pathLevel, count, pathDrops, isShowRoute = true) => {
        let getSVGName = Utils.getLevelName(pathLevel, floorData);
        if (getSVGName) {
            setTimeout(function () {
                let newGetSVGName = getSVGName;
                if (ZoomLevels) {
                    if (iZoom === 2) {
                        getSVGName = getSVGName + "_2";
                    } else if (iZoom === 3) {
                        getSVGName = getSVGName + "_4";
                    }
                }
                let fileName = Utils.getFloorImage(getSVGName);
                fetch(fileName)
                    .then((response) => {
                        if (!response.ok) {
                            toast.error("Network response was not ok");
                        }
                        return response.text();
                    })
                    .then((svgContent) => {
                        let data = {...homeData};
                        Utils.svgContainer(svgContent);
                        setPathDrops(pathDrops);
                        setPathCount(count);
                        Utils.drawPathForLevel(
                            pathLevel,
                            count,
                            pathDrops,
                            !isShowRoute,
                            floorData
                        );
                        Utils.setAllPOI(
                            pathLevel,
                            data.a,
                            data.b,
                            data.qr_type,
                            data.origin,
                            data.isInsideCall,
                            floorData
                        );
                        Utils.setFaviouriteIcon(pathLevel, floorData);
                        if (isShowRoute) {
                            document.getElementById("showRoute").classList.remove("d-none");
                            document
                                .getElementById("showRouteDiV")
                                .classList.remove("d-none");
                            document.getElementById("finishButton").classList.add("d-none");
                        } else {
                            Utils.drowRouteDots();
                        }
                        document
                            .getElementById(newGetSVGName)
                            .addEventListener("click", mallLayout);
                    })
                    .catch((error) => {
                        toast.error(Utils.getErrorMessage(error));
                    });
            }, 500);
        } else {
            toast.error("Oops...something went wrong. Please try again");
        }
    };

    const drawRoute = () => {
        let data = {...homeData};
        let element = document.getElementById("buttonAppender");
        let finishButton = document.getElementById("finishButton");
        element.classList.add("d-none");
        finishButton.classList.add("d-none");

        let level = store.getState().home.value.level;
        let start_level = data.start_level;
        if (parseInt(start_level) !== parseInt(level)) {
            loadSVG(start_level, pathCount, pathDrops, false);
        }
        Utils.drawPathForLevel(level, pathCount, pathDrops, true, floorData);

        Utils.drowRouteDots();

        let list = [...pathLevelList];
        if (list.length > 0) {
            Utils.setMapContinue(1);
            setNextFloorName(Utils.getLevelNameText(list[0], floorData));
        } else {
            Utils.setMapContinue(2);
        }
        setTimeout(function () {
            if (document.querySelector("#start-point")) {
                document.querySelector("#start-point").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
            } else if (document.querySelector("#end-point")) {
                document.querySelector("#end-point").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
            }
        }, 3000);
        document.getElementById("showRoute").classList.add("d-none");
        document.getElementById("showRouteDiV").classList.add("d-none");
    };

    const drawNextPath = () => {
        let list = [...pathLevelList];
        if (list.length > 0) {
            let data = {...homeData};
            data.level = list[0].toString();
            dispatch(homeActions.home(data));
            loadSVG(list[0], pathCount, pathDrops, false);
            //if (list.length > 0) {
            list = list.slice(1);
            if (list.length > 0) {
                Utils.setMapContinue(1);
                setNextFloorName(Utils.getLevelName(list[0], floorData));
            } else {
                Utils.setMapContinue(2);
            }
            setPathLevelList(list);
            setTimeout(function () {
                if (document.querySelector("#end-point")) {
                    document.querySelector("#end-point").scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                    });
                }
            }, 3000);
            //}
        }
    };

    const finishMap = (e = null) => {
        if (e) {
            e.preventDefault();
        }
        let data = {...homeData};
        data.a = data.b;
        data.start_level = data.level;
        data.b = "";
        data.min_value = "";
        data.meter_value = "";
        dispatch(homeActions.home(data));
        let lineEle = document.querySelectorAll(".lineSingh");
        if (lineEle) {
            lineEle.forEach((el) => el.remove());
        }
        let startPoint = document.querySelectorAll("#start-point");
        if (startPoint) {
            startPoint.forEach((el) => el.remove());
        }
        let showRoute = document.getElementById("showRoute");
        let showRouteDiV = document.getElementById("showRouteDiV");
        if (showRoute) {
            showRoute.classList.add("d-none");
            showRouteDiV.classList.add("d-none");
        }
        let finishBtn = document.getElementById("finishButton");
        if (finishBtn) {
            finishBtn.classList.add("d-none");
        }
        let mapIconEle = document.querySelectorAll("#Bcontinue-point");
        if (mapIconEle) {
            mapIconEle.forEach((el) => el.remove());
        }
        /** comment for caricon and origin icon does not remove start 27-6-2024*/

        // let mapIconEle = document.querySelectorAll("#carIconStart");
        // if (mapIconEle) {
        //   mapIconEle.forEach((el) => el.remove());
        // }

        // let mapIconLocationEle = document.querySelectorAll("#locationIconStart");
        // if (mapIconLocationEle) {
        //   mapIconLocationEle.forEach((el) => el.remove());
        // }

        /** comment for caricon and origin icon does not remove end 27-6-2024*/

        let endPointEle = document.getElementById("end-point");
        if (endPointEle) {
            endPointEle.setAttribute("href", AIcon);
        }
        // let carIconEndEle = document.getElementById("carIconEnd");
        // if (carIconEndEle) {
        //   carIconEndEle.classList.remove("d-none");
        //   carIconEndEle.setAttribute("id", "carIconStart");
        // }

        // let locationIconEndEle = document.getElementById("locationIconEnd");
        // if (locationIconEndEle) {
        //   locationIconEndEle.classList.remove("d-none");
        //   locationIconEndEle.setAttribute("id", "locationIconStart");
        // }
        let list = [...allFloorList];
        let index = list.findIndex(
            (x) => parseInt(x.floor) === parseInt(data.level)
        );
        if (index > -1) {
            setDummyFloor(list[index]["id"]);
        }
    };

    const increaseMapFloor = (e) => {
        e.preventDefault();
        document.getElementById("decrease-map").classList.remove("d-none");
        if (document.querySelector(".mallLayout")) {
            document.querySelector(".mallLayout").classList.remove("zoom-svg-in");
            setTimeout(function () {
                document.querySelector(".mallLayout").classList.add("zoom-svg-out");
            }, 500);
        }
        setTimeout(function () {
            let mapElement = document.querySelector("#map-floor");
            if (mapElement) {
                let level = mapElement.dataset.level;
                if (parseInt(level) === 0) {
                    level = 1;
                }
                if (level <= 5) {
                    let nextLevel = level;
                    if (level < 5) {
                        nextLevel = parseInt(level) + 1;
                    }
                    let getSVGName = Utils.getLevelName(nextLevel, floorData);
                    mapElement.value = getSVGName;
                    mapElement.dataset.level = nextLevel;
                    let mapElementLi = document.getElementById("li-" + getSVGName);
                    if (nextLevel === 5) {
                        document.getElementById("increase-map").classList.add("d-none");
                    }
                    if (mapElementLi) {
                        mapElementLi.click();
                    }
                }
            }
        }, 500);
    };
    const decreaseMapFloor = (e) => {
        e.preventDefault();
        document.getElementById("increase-map").classList.remove("d-none");
        if (document.querySelector(".mallLayout")) {
            document.querySelector(".mallLayout").classList.remove("zoom-svg-in");
            setTimeout(function () {
                document.querySelector(".mallLayout").classList.add("zoom-svg-out");
            }, 500);
        }
        setTimeout(function () {
            let mapElement = document.querySelector("#map-floor");
            if (mapElement) {
                let level = mapElement.dataset.level;
                let nextLevel = parseInt(level) - 1;
                if (nextLevel > -1) {
                    document.querySelector(".mallLayout").classList.add("zoom-svg-out");
                    let value = Utils.getLevelName(nextLevel, floorData);
                    mapElement.value = value;
                    if (value) {
                        mapElement.dataset.level = nextLevel;
                        let mapElementLi = document.getElementById("li-" + value);
                        mapElementLi.click();
                        if (nextLevel === 1) {
                            document.getElementById("decrease-map").classList.add("d-none");
                        }
                    }
                }
            }
        }, 500);
    };

    const handleInfoBar = (e) => {
        let data = {...helpData};
        data.isInfo = false;
        dispatch(HelpActions.setHelp(data));
    };


    return (
        <>
            {homeData?.client_name ? (
                <div className="map-block-row">
                    <div className="info-row">
                        <div
                            className="info-icon"
                            role={`button`}
                            onClick={handleInfoBar}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#sidebar-help"
                            ref={sideBarPopupOpen}
                        >
                            <img src={infoIcon} alt={`...`} style={{width: "16px"}}/>
                        </div>

                        <h3>Select A or B</h3>
                    </div>
                    <div className="map-marker-row">
                        <div className="plus-minus">
              <span className="input-group-btn">
                <button
                    type="button"
                    className="btn btn-default btn-number"
                    id={`increase-map`}
                    onClick={increaseMapFloor}
                >
                  <i className="fa fa-plus" aria-hidden="true"/>
                </button>
              </span>
                        </div>
                        <div className="select-col">
                            <div className="dropdown">
                                <button
                                    type="button"
                                    className="form-select-btn col-md-2 map-floor dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    id={`map-floor`}
                                    value={floor}
                                    data-value={iZoom}
                                    data-level={floorNumber}
                                >
                                    {floorText}
                                </button>
                                <ul className="dropdown-menu">
                                    {allFloorList &&
                                    allFloorList.map((item, i) => (
                                        <li key={i}>
                        <span
                            className={`dropdown-item cursor-pointer`}
                            id={`li-${item.id}`}
                            onClick={(e) => handelChangeFloor(e, item)}
                        >
                          {item.name}
                        </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/*<select className="form-select col-md-2 map-floor" onChange={handelChangeFloor} value={floor}
                         id={`map-floor`}>
                     <option value="" data-level={0}>Select floor</option>
                     <option value="garageFloor" data-level={1}>Garage Floor</option>
                     <option value="groundFloor" data-level={2}>Ground Floor</option>
                     <option value="firstFloor" data-level={3}>First Floor</option>
                     <option value="secondFloor" data-level={4}>Second Floor</option>
                     <option value="thirdFloor" data-level={5}>Third Floor</option>
                 </select>*/}
                            {/* <button type={`button`} className={`d-none`} id={`map-show-flow`}
                         onClick={handelChangeFloor}/>*/}
                            <button
                                type={`button`}
                                className={`d-none`}
                                id={`searchMap`}
                                onClick={handleFindMap}
                            />
                        </div>
                        <div className="plus-link">
              <span className="input-group-btn">
                <button
                    type="button"
                    className="btn btn-default btn-number"
                    id={`decrease-map`}
                    onClick={decreaseMapFloor}
                >
                  <i className="fa fa-minus" aria-hidden="true"/>
                </button>
              </span>
                        </div>
                    </div>
                </div>
            ) : null}

            {/*<div>
                <button type={`button`} onClick={onZoomOut} className={`btn btn-info me-2 btn-sm`}>-</button>
                <button type={`button`} onClick={onZoomIn} className={`btn btn-primary btn-sm`}>+</button>
            </div>*/}
            <div
                id={`showMapContainer`}
                className={
                    ZoomLevels
                        ? `overflow-auto cursor-pointer cursor-pointer showMapContainer`
                        : "overflow-auto cursor-pointer zoom-svg-in showMapContainer"
                }
                // className={`overflow-auto cursor-pointer showMapContainer`}
                style={{marginBottom: "60px"}}
            />
            <div className="col-btn-row">
                <div className="container">
                    <div className="container-button">
                        <div id={`showRouteDiV`}>
                            <button
                                type={`button`}
                                id="showRoute"
                                onClick={() => {
                                    drawRoute();
                                    SetRouteDisplayFlag(RouteFlagEnum.SHOW_ROUTE);
                                }}
                                className=" d-none btnroute"
                            >
                                <i className="icon-ro">
                                    <img src={mapLine} alt={`...`}/>
                                </i>
                                Show Route
                                <br/>
                            </button>
                            <span className={`meterValue`}>
                {meterValue && (
                    <>
                        {homeData?.clientData?.show_meter_or_feet === "Meters" && (
                            <div>
                                {FeetValue?.distance_in_meters} meters{" "}
                                {FeetValue?.time_in_minutes}min
                            </div>
                        )}
                        {homeData?.clientData?.show_meter_or_feet === "feet" && (
                            <div>
                                {FeetValue?.distance_in_feet} ft{" "}
                                {FeetValue?.time_in_minutes}min
                            </div>
                        )}
                    </>
                )}
              </span>
                        </div>
                        <div id={`buttonAppender`} className="btn-row-block d-none">
                            <button
                                id="continueButton"
                                type={`button`}
                                onClick={drawNextPath}
                                className="btn-route btn-continue"
                            >
                                <i className="fa fa-forward" aria-hidden="true"></i>
                                &nbsp;Continue
                            </button>
                            <span>On {nextFloorName}</span>
                        </div>

                        <button
                            type={`button`}
                            id="finishButton"
                            onClick={(e) => {
                                finishMap(e);
                                SetRouteDisplayFlag(RouteFlagEnum.FINISH_ROUTE);
                            }}
                            className="btn-route btn-finish d-none"
                        >
                            <i className="fa fa-flag-checkered fa-2x" aria-hidden="true"></i>
                            &nbsp; Finish
                        </button>
                    </div>
                    <div className="plus-minus-row">
                        {/* {!ZoomLevels ? ( */}
                        <i
                            // className={
                            //   ZoomLevels ? "fa fa-search-minus d-none" : "fa fa-search-minus"
                            // }
                            className="fa fa-search-minus"
                            aria-hidden="true"
                            id={`zoom-out`}
                            onClick={onZoomOut}
                        />
                        {/* ) : null} */}

                        <i
                            className="fa fa-search-plus"
                            aria-hidden="true"
                            onClick={onZoomIn}
                        />
                    </div>
                </div>
            </div>
            <div
                className="offcanvas offcanvas-start sidebar-help"
                tabIndex="-1"
                id={`sidebar-help`}
                data-bs-keyboard="false"
                aria-labelledby="offcanvasExampleLabel"
            >
                <div className="offcanvas-header">
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body">
                    <div className="d-flex flex-column h-100">
                        <div className="container">
                            {helpData.isInfo === true && (
                                <>
                                    {helpData.getInfo?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {parseInt(item.qr_type) ===
                                            parseInt(homeData.qr_type) && (
                                                <div className="row mb-4" key={index}>
                                                    {item.info_text && (
                                                        <div className="col-9">{item.info_text}</div>
                                                    )}
                                                    <div className="col-3 text-end">
                                                        {item?.info_image && (
                                                            <img src={item?.info_image} alt={`...`}/>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </>
                            )}

                            {helpData.isInfo === false && (
                                <>
                                    {helpData.getHelp?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {item.help_identifier === "map" && (
                                                <div className="row mb-4" key={index}>
                                                    {item?.help_text && (
                                                        <div className="col-9">{item.help_text}</div>
                                                    )}
                                                    <div className="col-3 text-end">
                                                        {item?.help_image && (
                                                            <img
                                                                src={item?.help_image}
                                                                alt={`...`}
                                                                // style={{ width: "46px" }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Map;
