import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const name = 'language';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const languageActions = {...slice.actions, ...extraActions};
export const languageReducer = slice.reducer;

function createInitialState() {
    return {
        value: [
            {name: 'English', lang: 'en', icon: 'images/language/flag-uk.png', active: true},
            {
                name: 'French',
                lang: 'fr',
                icon: 'https://www.worldometers.info//img/flags/small/tn_fr-flag.gif',
                active: false
            },
            {name: 'German', lang: 'de', icon: 'images/language/german-flag.png', active: false},
            {name: 'Spanish', lang: 'es', icon: 'images/language/spanish.png', active: false},
            {
                name: 'Italian',
                lang: 'it',
                icon: 'https://www.worldometers.info//img/flags/small/tn_it-flag.gif',
                active: false
            },
            {
                name: 'Hindi',
                lang: 'hi',
                icon: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif',
                active: false
            },
            {
                name: 'Swedish',
                lang: 'sv',
                icon: 'https://www.worldometers.info//img/flags/small/tn_sw-flag.gif',
                active: false
            },
            {
                name: 'Arabic',
                lang: 'ar',
                icon: 'https://www.worldometers.info//img/flags/small/tn_ae-flag.gif',
                active: false
            },
            {
                name: 'Greek',
                lang: 'el',
                icon: 'https://www.worldometers.info//img/flags/small/tn_gr-flag.gif',
                active: false
            },
            {
                name: 'Russian',
                lang: 'ru',
                icon: 'https://www.worldometers.info//img/flags/small/tn_rs-flag.gif',
                active: false
            },
            {
                name: 'Chinese',
                lang: 'zh-CN',
                icon: 'https://www.worldometers.info//img/flags/small/tn_ch-flag.gif',
                active: false
            },
            {
                name: 'Slovak',
                lang: 'sk',
                icon: 'https://www.worldometers.info//img/flags/small/tn_lo-flag.gif',
                active: false
            },
            {
                name: 'Czech',
                lang: 'cs',
                icon: 'https://www.worldometers.info//img/flags/small/tn_ez-flag.gif',
                active: false
            },
            {
                name: 'Danish',
                lang: 'da',
                icon: 'https://www.worldometers.info//img/flags/small/tn_da-flag.gif',
                active: false
            },
            {
                name: 'Portugues',
                lang: 'pt',
                icon: 'https://www.worldometers.info//img/flags/small/tn_po-flag.gif',
                active: false
            },
            {
                name: 'Japanese',
                lang: 'ja',
                icon: 'https://www.worldometers.info//img/flags/small/tn_ja-flag.gif',
                active: false
            },
            {
                name: 'Slovenian',
                lang: 'sl',
                icon: 'https://www.worldometers.info//img/flags/small/tn_si-flag.gif',
                active: false
            },
        ]
    }
}

function createReducers() {
    return {
        setLang
    };

    function setLang(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        lang: lang()
    };

    function lang() {
        return createAsyncThunk(
            `${name}/load`,
            function (arg, {dispatch}) {
                dispatch(languageActions.setLang(arg));
            }
        );
    }
}