import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const name = 'menu';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const menuActions = {...slice.actions, ...extraActions};
export const menuReducer = slice.reducer;

function createInitialState() {
    return {
        value: false
    }
}

function createReducers() {
    return {
        setMenu
    };

    function setMenu(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        menu: menu()
    };

    function menu() {
        return createAsyncThunk(
            `${name}/load`,
            function (arg, {dispatch}) {
                dispatch(menuActions.setMenu(arg));
            }
        );
    }
}