import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const name = "Help";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const HelpActions = { ...slice.actions, ...extraActions };
export const HelpReducer = slice.reducer;

function createInitialState() {
  return {
    value: {
      getInfo: [],
      getHelp: [],
      isInfo: true,
    },
  };
}

function createReducers() {
  return {
    setHelp,
  };

  function setHelp(state, action) {
    state.value = action.payload;
  }
}

function createExtraActions() {
  return {
    Help: Help(),
  };

  function Help() {
    return createAsyncThunk(`${name}/load`, function (arg, { dispatch }) {
      dispatch(HelpActions.setHelp(arg));
    });
  }
}
