import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const name = 'tab';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const tabActions = {...slice.actions, ...extraActions};
export const tabReducer = slice.reducer;

function createInitialState() {
    return {
        value: {
            tab: 4
        }
    }
}

function createReducers() {
    return {
        setTab
    };

    function setTab(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        home: home()
    };

    function home() {
        return createAsyncThunk(
            `${name}/load`,
            function (arg, {dispatch}) {
                dispatch(tabActions.setTab(arg));
            }
        );
    }
}