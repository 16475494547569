import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

import Utils from "../utils";
import { getLocationFavList } from "../services/Common";
import trashIcon from "../assets/images/trash.svg";
import infoIcon from "../assets/images/question_mark.png";
import mapLine from "../assets/images/map-line.svg";
import { homeActions, tabActions } from "../store";

function Favourite() {
  const dispatch = useDispatch();
  const homeData = useSelector((x) => x.home.value);
  const helpData = useSelector((x) => x.help.value);

  const [locationList, setLocationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    location_ids: Utils.getLocalCookie(),
  });
  useEffect(() => {
    if (homeData?.client_name) {
      setIsLoading(true);
      getLocationFavList(params)
        .then((response) => {
          response = response.data;
          setLocationList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [params, homeData?.client_name]);
  const removeFavourite = (e, id) => {
    e.preventDefault();
    Utils.manageCookie(id, false);
    let data = { ...params };
    data.location_ids = Utils.getLocalCookie();
    setParams(data);
  };
  const drawRouteList = (e) => {
    e.preventDefault();
    dispatch(tabActions.home({ tab: 4 }));
  };
  const handleLocationBFav = (e, item) => {
    e.preventDefault();
    let data = { ...homeData };
    let isCheckInput = false;
    let input = sessionStorage.getItem("input");
    if (item.name === data.a) {
      toast.error("A or B can not same.Please select another");
    } else {
      if (input) {
        if (input === "A") {
          data.a = item.name;
          data.start_level = item.level;
          data.info_raw = "Select Start (A)";
          isCheckInput = true;
        } else if (input === "B") {
          data.b = item.name;
          data.info_raw = "Select Destination (B)";
          isCheckInput = true;
        }
      }
      if (!isCheckInput) {
        if (!data.a) {
          data.a = item.name;
          data.start_level = item.level;
        } else {
          data.b = item.name;
        }
      }
    }
    dispatch(homeActions.home(data));
  };
  return (
    <>
      <div className="location-block">
        <div className="row">
          <div className="col-8">
            <h3>
              <span
                className="info-icon"
                role={`button`}
                data-bs-toggle="offcanvas"
                data-bs-target="#sidebar-favourite"
              >
                <img src={infoIcon} alt={`...`} style={{ width: "16px" }} />
                &nbsp;Select Destination (B)
              </span>
            </h3>
          </div>
          <div className="col-4">
            {homeData.a && homeData.b && (
              <div>
                <button
                  type={`button`}
                  onClick={drawRouteList}
                  className="btnroute float-end"
                >
                  <i className="icon-ro">
                    <img src={mapLine} alt={`...`} />
                  </i>
                  <span>Show Route</span>
                </button>
                {homeData.meter_value && (
                  <span className={`float-end meterValue`}>
                      {homeData.meter_value}{" "}
                        {homeData?.clientData?.show_meter_or_feet}{" "}
                        {homeData.min_value}{" "}
                        {
                          homeData?.clientData
                            ?.dominent_criteria_path_time_distance
                        }
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading &&
        Array.from(Array(5)).map((_, index) => (
          <div className="tab-content-table is-loading" key={index}></div>
        ))}
      {!isLoading && locationList && locationList.length === 0 && (
        <div>
          <h3 className={`text-center`}>Records not found</h3>
        </div>
      )}
      <div className={`list-view`}>
        {!isLoading &&
          locationList &&
          locationList.map((item, index) => (
            <div className="tab-content-table cursor-pointer" key={index}>
              <div className="col-table-6 notranslate">
                <h4 onClick={(e) => handleLocationBFav(e, item)}>
                  {item.name}
                </h4>
              </div>
              <div className="col-table-3">
                <div className="logo-images-fav logo-trash">
                  <NavLink
                    to={"/"}
                    onClick={(e) => removeFavourite(e, item.id)}
                  >
                    <img width="20" src={trashIcon} alt="trash" />
                  </NavLink>
                </div>
              </div>
              <div className="col-table-3">
                <NavLink className="icon-images" to={`/location/${item.uuid}`}>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </NavLink>
              </div>
            </div>
          ))}
      </div>

      <div
        className="offcanvas offcanvas-start sidebar-favourite"
        tabIndex="-1"
        id={`sidebar-favourite`}
        aria-labelledby="offcanvasExampleLabelCategory"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <div className="d-flex flex-column h-100">
            <div className="container">
              {helpData?.getHelp?.map((item, index) => (
                <>
                  {item.help_identifier === "favorite" && (
                    <div className="row mb-4" key={index}>
                      {item?.help_text && (
                        <div className="col-10">{item.help_text}</div>
                      )}
                      <div className="col-2 text-end">
                        {item?.help_image && (
                          <img
                            src={item?.help_image}
                            alt={`...`}
                            style={{ width: "35px" }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Favourite;
