import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const name = "home";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const homeActions = { ...slice.actions, ...extraActions };
export const homeReducer = slice.reducer;

function createInitialState() {
  return {
    value: {
      a: "Main Entrance",
      start_level: 2,
      b: "",
      search_area: false,
      scanner: false,
      level: 2,
      meter_value: "",
      qr_type: "",
      origin: "",
      min_value: "",
      info_raw: "Select Destination (B)",
      main_category: false,
      client_name: "",
      clientData: {},
      isInsideCall: false,
      getAboutInfo:[],
    },
  };
}

function createReducers() {
  return {
    setHome,
  };

  function setHome(state, action) {
    state.value = action.payload;
  }
}

function createExtraActions() {
  return {
    home: home(),
  };

  function home() {
    return createAsyncThunk(`${name}/load`, function (arg, { dispatch }) {
      dispatch(homeActions.setHome(arg));
    });
  }
}
