import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function Layout() {
  const load = useSelector((x) => x.loader.value);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    document.addEventListener("touchforcechange", disablePinchZoom, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);

  return (
    <>
      {load && (
        <div className="page-loader">
          <div className="spinner-grow" />
        </div>
      )}
      <Outlet />
    </>
  );
}

export default Layout;
