import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const name = "floor";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const floorActions = { ...slice.actions, ...extraActions };
export const floorReducer = slice.reducer;

function createInitialState() {
  return {
    value: [
      {
        id: "garageFloor",
        name: "Garage",
        floor: 1,
        img_path: "https://actron.playcrazzy.com/uploads/floor/garageFloor.svg",
      },
      {
        id: "groundFloor",
        name: "Ground Floor",
        floor: 2,
        img_path: "https://actron.playcrazzy.com/uploads/floor/groundFloor.svg",
      },
      {
        id: "firstFloor",
        name: "1st Floor",
        floor: 3,
        img_path: "https://actron.playcrazzy.com/uploads/floor/firstFloor.svg",
      },
      {
        id: "secondFloor",
        name: "2nd Floor",
        floor: 4,
        img_path: "https://actron.playcrazzy.com/uploads/floor/secondFloor.svg",
      },
      {
        id: "thirdFloor",
        name: "3rd Floor",
        floor: 5,
        img_path: "https://actron.playcrazzy.com/uploads/floor/thirdFloor.svg",
      },
    ],
  };
}

function createReducers() {
  return {
    setFloor,
  };

  function setFloor(state, action) {
    state.value = action.payload;
  }
}

function createExtraActions() {
  return {
    floor: floor(),
  };

  function floor() {
    return createAsyncThunk(`${name}/load`, function (arg, { dispatch }) {
      dispatch(floorActions.setFloor(arg));
    });
  }
}
