import {useEffect} from 'react';
import configDb from "../config";

function useDocumentTitle(title) {
    useEffect(() => {
        let pageTitle = configDb.data.app_name;
        if (title) {
            pageTitle = title + ' - ' + pageTitle;
        }
        document.title = pageTitle;
    }, [title]);
}

export default useDocumentTitle;