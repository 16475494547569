import {Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";

import Home from "./views/Home";
import Details from "./views/location/Details";
import Scanner from "./components/Scanner";
import {Provider} from "react-redux";
import {store} from "./store";
import Layout from "./views/layout";
import About from "./views/About";

function App() {
    return (
        <Provider store={store}>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick
                            rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored"/>
            <Routes>
                <Route path={'/'} element={<Layout/>}>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/scanner'} element={<Scanner/>}/>
                    <Route path={'/location/:uuid'} element={<Details/>}/>
                    <Route path={'/about'} element={<About/>}/>
                </Route>
            </Routes>
        </Provider>
    );
}

export default App;
