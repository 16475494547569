import axios from 'axios';
import configDb from "../config";
import {store} from "../store";

let API_URL = configDb.data.api_url;

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json",
        accept: "*/*",
    },
});

axiosInstance.interceptors.request.use((config) => {
    let auth = store.getState().home.value;
    if (auth?.client_name) {
        config.headers.token = auth.client_name;
    }
    return config;
});
export default axiosInstance;