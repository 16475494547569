import { configureStore } from "@reduxjs/toolkit";

import { homeReducer } from "./home.slice";
import { loaderReducer } from "./loader.slice";
import { tabReducer } from "./tab.slice";
import { languageReducer } from "./language.slice";
import { floorReducer } from "./floor.slice";
import { menuReducer } from "./menu.slice";
import { infoReducer } from "./info.slice";
import { HelpReducer } from "./help.slice";

export * from "./home.slice";
export * from "./loader.slice";
export * from "./tab.slice";
export * from "./language.slice";
export * from "./floor.slice";
export * from "./menu.slice";
export * from "./info.slice";
export * from "./help.slice";

export const store = configureStore({
  reducer: {
    home: homeReducer,
    loader: loaderReducer,
    menu: menuReducer,
    tab: tabReducer,
    language: languageReducer,
    floor: floorReducer,
    info:infoReducer,
    help:HelpReducer
  },
});
