import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const name = 'info';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({name, initialState, reducers});

export const infoAction = {...slice.actions, ...extraActions};
export const infoReducer = slice.reducer;

function createInitialState() {
    return {
        value: false
    }
}

function createReducers() {
    return {
        setInfo
    };

    function setInfo(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    return {
        info: info()
    };

    function info() {
        return createAsyncThunk(
            `${name}/load`,
            function (arg, {dispatch}) {
                dispatch(infoAction.setInfo(arg));
            }
        );
    }
}