import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getLocationList } from "../services/Common";
import Utils from "../utils";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { homeActions, tabActions } from "../store";
import infoIcon from "../assets/images/question_mark.png";
import mapLine from "../assets/images/map-line.svg";

function Location() {
  const dispatch = useDispatch();
  const homeData = useSelector((x) => x.home.value);
  const helpData = useSelector((x) => x.help.value);
  const [locationList, setLocationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (homeData?.client_name) {
      setIsLoading(true);
      getLocationList()
        .then((response) => {
          response = response.data;
          setLocationList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [homeData?.client_name]);

  const handleLocationB = (e, item) => {
    e.preventDefault();
    let data = { ...homeData };
    let isCheckInput = false;
    let input = sessionStorage.getItem("input");
    if (item.name === data.a && data.b !== "") {
      toast.error("A or B can not same.Please select another");
    } else if (item.name === data.b && data.a !== "") {
      toast.error("A or B can not same.Please select another");
    } else if (item.name === data.a && data.b === "") {
      toast.error("A or B can not same.Please select another");
    } else {
      if (input) {
        if (input === "A") {
          data.a = item.name;
          data.start_level = item.level;
          data.info_raw = "Select Start (A)";
          isCheckInput = true;
        } else if (input === "B") {
          data.b = item.name;
          data.info_raw = "Select Destination (B)";
          isCheckInput = true;
        }
      }
      if (!isCheckInput) {
        if (!data.a) {
          data.a = item.name;
          data.start_level = item.level;
        } else {
          data.b = item.name;
        }
      }
      dispatch(homeActions.home(data));
    }
  };
  const drawRouteList = (e) => {
    e.preventDefault();

    let locationElement = document.getElementsByClassName("tab-content-table");
    for (let i = 0; i < locationElement.length; i++) {
      locationElement[i].classList.remove("d-none");
    }

    dispatch(tabActions.home({ tab: 4 }));
  };

  return (
    <>
      <div className="select-block">
        <h3>
          <span
            className="info-icon"
            role={`button`}
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebar-list"
          >
            <img src={infoIcon} alt={`...`} style={{ width: "16px" }} />
          </span>
          &nbsp;&nbsp;
          <span id={`location-head`}>{homeData?.info_raw}</span>
        </h3>
        {homeData.a && homeData.b && homeData.a !== homeData.b && (
          <div>
            <button
              type={`button`}
              onClick={drawRouteList}
              className="btnroute"
            >
              <i className="icon-ro">
                <img src={mapLine} alt={`...`} />
              </i>
              <span>Show Route</span>
            </button>
            {homeData.meter_value && (
              <span className={`float-end meterValue`}>
                {homeData.meter_value}{" "}
                {homeData?.clientData?.show_meter_or_feet} {homeData.min_value}{" "}
                {homeData?.clientData?.dominent_criteria_path_time_distance}
              </span>
            )}
          </div>
        )}
      </div>
      {isLoading &&
        Array.from(Array(5)).map((_, index) => (
          <div className="tab-content-table is-loading" key={index}></div>
        ))}

      <div className={`list-view`}>
        {!isLoading &&
          locationList &&
          locationList.map((item, index) => (
            <div className="tab-content-table cursor-pointer" key={index}>
              <div className="col-table-6 notranslate">
                <h4 onClick={(e) => handleLocationB(e, item)}>{item.name}</h4>
              </div>
              <div className="col-table-3">
                <div className="logo-images">
                  <img width="106" src={item.image} alt={item.name} />
                </div>
              </div>
              <div className="col-table-3">
                <NavLink className="icon-images" to={`/location/${item.uuid}`}>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </NavLink>
              </div>
            </div>
          ))}
      </div>
      {!isLoading && locationList && locationList.length === 0 && (
        <div>
          <h3 className={`text-center`}>Records not found</h3>
        </div>
      )}

      <div
        className="offcanvas offcanvas-start sidebar-help"
        tabIndex="-1"
        id={`sidebar-list`}
        aria-labelledby="offcanvasExampleLabelList"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <div className="d-flex flex-column h-100">
            <div className="container">
              {helpData.getHelp?.map((item, index) => (
                <React.Fragment key={index}>
                  {item.help_identifier === "list" && (
                    <div className="row mb-4">
                      {item.help_text && (
                        <div className="col-9">{item.help_text}</div>
                      )}
                      <div className="col-3 text-end">
                        {item?.help_image && (
                          <img src={item?.help_image} alt={`...`} />
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Location;
