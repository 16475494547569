import configDb from "../config";
import { getAllPOI, getFAVPoi } from "../services/Common";
import favIcon from "../assets/images/map-star.svg";
import carIcon from "../assets/images/car.svg";
import locationIcon from "../assets/images/new-loation.svg";
import BIcon from "../assets/images/B.png";
import AIcon from "../assets/images/A.png";
import StopIcon from "../assets/images/yellow circle.svg";

class Utils {
  static getErrorMessage(err) {
    let error = "Oops...something went wrong.";
    if (err.response && err.response.data) {
      let data = err.response.data;
      if (data.message) {
        error = data.message;
      }
    } else if (err.message) {
      error = err.message;
    }
    return error;
  }

  static setLocalCookie(value) {
    localStorage.setItem(configDb.data.fev_storage, JSON.stringify(value));
  }

  static getLocalCookie() {
    let value = localStorage.getItem(configDb.data.fev_storage);
    let data = [];
    if (value) {
      data = JSON.parse(value);
    }
    return data;
  }

  static manageCookie(id, check) {
    let value = this.getLocalCookie();
    if (value.length > 0) {
      if (check) {
        value.push(id.toString());
        this.setLocalCookie(value);
      } else {
        let index = value.findIndex((x) => x === id.toString());
        if (index > -1) {
          value.splice(index, 1);
          this.setLocalCookie(value);
        }
      }
    } else if (check) {
      let data = [id.toString()];
      this.setLocalCookie(data);
    }
  }

  static checkCookie(id) {
    let value = this.getLocalCookie();
    let checked = false;
    if (value.length > 0) {
      if (value.indexOf(id.toString()) > -1) {
        checked = true;
      }
    }
    return checked;
  }

  static getLevelName(level, floorList = []) {
    let levelName = "";
    let idx = floorList.findIndex((x) => parseInt(x.floor) === parseInt(level));
    if (idx > -1) {
      levelName = floorList[idx]["id"];
    } else {
      let index = configDb.data.levelList.findIndex(
        (x) => x.id === parseInt(level)
      );
      if (index > -1) {
        levelName = configDb.data.levelList[index]["name"];
      }
    }
    return levelName;
  }

  static getLevelNameText(level, floorList = []) {
    let levelName = "";
    let idx = floorList.findIndex((x) => parseInt(x.floor) === parseInt(level));
    if (idx > -1) {
      levelName = floorList[idx]["id"];
    } else {
      let index = configDb.data.levelList.findIndex(
        (x) => x.id === parseInt(level)
      );
      if (index > -1) {
        levelName = configDb.data.levelList[index]["name"];
      }
    }
    return levelName;
  }

  static drawPathForLevel(
    level,
    count,
    pathData,
    isDraw = false,
    floorData = []
  ) {
    if (level) {
      document.querySelectorAll(".lineSingh").forEach((el) => el.remove());
      document.querySelectorAll("#start-point").forEach((el) => el.remove());
      document.querySelectorAll("#end-point").forEach((el) => el.remove());

      let svgTarget = this.getLevelName(level, floorData);
      let isIphone = 0;
      let whichImage = 0;
      let divideBy = 1;
      let routeWidth = 11;
      let routeDesh = "1,14";
      if (navigator.userAgent.match(/iPhone/i)) {
        let mapElement = document.querySelector("#map-floor");

        if (mapElement) {
          isIphone = 1;
          whichImage = mapElement.dataset.value;
          if (mapElement.dataset.value) {
            if (parseInt(mapElement.dataset.value) === 2) {
              divideBy = 2;
              routeWidth = 7;
              routeDesh = "1,10";
            } else if (parseInt(mapElement.dataset.value) === 3) {
              divideBy = 4;
              routeWidth = 4;
              routeDesh = "1,9";
            }
          }
        }
      }

      let isLast = 0;
      for (let pc = 1; pc <= count; pc++) {
        if (pathData[pc] !== undefined) {
          if (pathData[pc] && pathData[pc]["L" + pc]) {
            let levelPath = pathData[pc]["L" + pc];
            if (parseInt(levelPath) === parseInt(level)) {
              if (pc === 1) {
                isLast = 2;
              }
              if (pc === count && isLast === 0) {
                isLast = 1;
              }
            }
          }
        }
      }

      let lastIndex = 0;
      let isShowContinue = 0;
      for (let pc = 1; pc <= count; pc++) {
        if (pathData[pc] !== undefined) {
          if (pathData[pc] && pathData[pc]["L" + pc]) {
            let xPath = pathData[pc]["X" + pc];
            let yPath = pathData[pc]["Y" + pc];
            let levelPath = pathData[pc]["L" + pc];

            if (parseInt(levelPath) === parseInt(level)) {
              lastIndex++;
              var newItem = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "p"
              );
              if (isDraw) {
                document.getElementById(svgTarget).appendChild(newItem);
              }

              if (pc === 1) {
                if (!isDraw) {
                  document.getElementById(svgTarget).appendChild(newItem);
                }
                let myTextElement = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "image"
                );
                /*var myText = document.createTextNode("A");*/
                myTextElement.setAttributeNS(
                  "http://www.w3.org/1999/xlink",
                  "href",
                  AIcon
                );

                myTextElement.setAttribute("x", xPath / divideBy - 21.5);
                myTextElement.setAttribute("y", yPath / divideBy - 20.5);
                myTextElement.setAttribute("id", "start-point");

                if (isIphone == 1) {
                  if (whichImage == 2) {
                    myTextElement.setAttribute("class", "AIconType2");
                    myTextElement.setAttribute("x", xPath / divideBy - 18);
                    myTextElement.setAttribute("y", yPath / divideBy - 18);
                  } else if (whichImage == 3) {
                    myTextElement.setAttribute("class", "AIconType4");
                    myTextElement.setAttribute("x", xPath / divideBy - 12);
                    myTextElement.setAttribute("y", yPath / divideBy - 12);
                  } else {
                    myTextElement.setAttribute("class", "AIconTypeElse");
                  }
                }

                /*myTextElement.appendChild(myText);*/
                newItem.after(myTextElement);
              }
              /* continue start icon code begin */
              if (isLast === 1 && isShowContinue === 0) {
                isShowContinue++;
                if (!isDraw) {
                  document.getElementById(svgTarget).appendChild(newItem);
                }
                let myTextElementC = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "image"
                );
                myTextElementC.setAttributeNS(
                  "http://www.w3.org/1999/xlink",
                  "href",
                  StopIcon
                );
                myTextElementC.setAttribute(
                  "class",
                  "lineSingh d-none notranslate"
                );
                myTextElementC.setAttribute("x", xPath / divideBy - 21.5);
                myTextElementC.setAttribute("y", yPath / divideBy - 20.5);
                myTextElementC.setAttribute("id", "Bcontinue-point");

                if (isIphone == 1) {
                  if (whichImage == 2) {
                    myTextElementC.setAttribute("class", "StopIconType2");
                    myTextElementC.setAttribute("x", xPath / divideBy - 18);
                    myTextElementC.setAttribute("y", yPath / divideBy - 14);
                  } else if (whichImage == 3) {
                    myTextElementC.setAttribute("class", "StopIconType4");
                    myTextElementC.setAttribute("x", xPath / divideBy - 10);
                    myTextElementC.setAttribute("y", yPath / divideBy - 10);
                  } else {
                    myTextElementC.setAttribute("class", "StopIconTypeElse");
                  }
                }
                /*myTextElement.appendChild(myText);*/
                document.getElementById(svgTarget).appendChild(myTextElementC);
              }
              /* continue start icon code end */

              if (parseInt(pc) !== 1 && isDraw) {
                let oldCounter = pc - 1;
                let oldXPath = pathData[oldCounter]["X" + oldCounter];
                let oldYPath = pathData[oldCounter]["Y" + oldCounter];

                var newLineItem = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "line"
                );
                newLineItem.setAttribute(
                  "class",
                  "lineSingh d-none notranslate " + pathData[pc]["showhide"]
                );
                newLineItem.setAttribute("id", "lineSingh_" + pc);
                newLineItem.setAttribute("x1", oldXPath / divideBy);
                newLineItem.setAttribute("y1", oldYPath / divideBy);
                newLineItem.setAttribute("x2", xPath / divideBy);
                newLineItem.setAttribute("y2", yPath / divideBy);
                newLineItem.setAttribute("stroke-dasharray", routeDesh);
                newLineItem.setAttribute("stroke-miterlimit", 1);
                newLineItem.setAttribute("stroke-width", routeWidth);
                newLineItem.setAttribute("stroke-linecap", "round");
                newLineItem.setAttribute("stroke", "#c40d10");
                document.getElementById(svgTarget).appendChild(newLineItem);
              }
              if (pc === count) {
                let myTextElementB = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "image"
                );
                /*var myTextB = document.createTextNode("B");*/
                myTextElementB.setAttribute("x", xPath / divideBy - 22);
                myTextElementB.setAttribute("y", yPath / divideBy - 20);
                myTextElementB.setAttribute("id", "end-point");
                myTextElementB.setAttributeNS(
                  "http://www.w3.org/1999/xlink",
                  "href",
                  BIcon
                );

                if (isIphone == 1) {
                  if (whichImage == 2) {
                    myTextElementB.setAttribute("class", "BIconType2");
                    myTextElementB.setAttribute("x", xPath / divideBy - 18);
                    myTextElementB.setAttribute("y", yPath / divideBy - 14);
                  } else if (whichImage == 3) {
                    myTextElementB.setAttribute("class", "BIconType4");
                    myTextElementB.setAttribute("x", xPath / divideBy - 10);
                    myTextElementB.setAttribute("y", yPath / divideBy - 10);
                  } else {
                    myTextElementB.setAttribute("class", "BIconTypeElse");
                  }
                }

                if (!isDraw) {
                  document.getElementById(svgTarget).appendChild(newItem);
                }

                /*myTextElementB.appendChild(myTextB);*/
                newItem.after(myTextElementB);
              }
            }
          }
        }
      }

      /* Continue stop icon code begin */
      let startIndex = 0;
      for (let pc = 1; pc <= count; pc++) {
        if (pathData[pc] !== undefined) {
          if (pathData[pc] && pathData[pc]["L" + pc]) {
            let xPath = pathData[pc]["X" + pc];
            let yPath = pathData[pc]["Y" + pc];
            let levelPath = pathData[pc]["L" + pc];
            if (parseInt(levelPath) === parseInt(level)) {
              ++startIndex;
              if (startIndex === lastIndex && pc !== count && isDraw) {
                if (!isDraw) {
                  document.getElementById(svgTarget).appendChild(newItem);
                }
                let myTextElementSC = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "image"
                );
                myTextElementSC.setAttributeNS(
                  "http://www.w3.org/1999/xlink",
                  "href",
                  StopIcon
                );
                myTextElementSC.setAttribute(
                  "class",
                  "lineSingh d-none notranslate " + pathData[pc]["showhide"]
                );
                myTextElementSC.setAttribute("x", xPath / divideBy - 21.5);
                myTextElementSC.setAttribute("y", yPath / divideBy - 20.5);
                myTextElementSC.setAttribute("id", "continue-point");
                /*myTextElement.appendChild(myText);*/
                document.getElementById(svgTarget).appendChild(myTextElementSC);

                if (isIphone == 1) {
                  if (whichImage == 2) {
                    myTextElementSC.setAttribute("class", "StopIconType2");
                    myTextElementSC.setAttribute("x", xPath / divideBy - 18);
                    myTextElementSC.setAttribute("y", yPath / divideBy - 14);
                  } else if (whichImage == 3) {
                    myTextElementSC.setAttribute("class", "StopIconType4");
                    myTextElementSC.setAttribute("x", xPath / divideBy - 10);
                    myTextElementSC.setAttribute("y", yPath / divideBy - 10);
                  } else {
                    myTextElementSC.setAttribute("class", "StopIconTypeElse");
                  }
                }
              }
            }
          }
        }
      }
      /* Continue stop icon code end */
    }
  }

  static svgContainer(data) {
    let element = document.getElementById("tab_4");
    element.click();
    document.getElementById("showMapContainer").innerHTML = data;
  }

  static setMapContinue(data) {
    let element = document.getElementById("buttonAppender");
    let finishButton = document.getElementById("finishButton");
    if (element) {
      if (data === 1) {
        element.classList.remove("d-none");
        finishButton.classList.add("d-none");
      } else if (data === 2) {
        element.classList.add("d-none");
        finishButton.classList.remove("d-none");
      }
    }
  }

  static setStoreStorage(data) {
    let store = localStorage.getItem(configDb.data.store_storage);
    if (store) {
      store = JSON.parse(store);
      let index = store.findIndex(
        (x) => x.from === data.from && x.to === data.to
      );
      if (index === -1) {
        store.push(data);
        localStorage.setItem(
          configDb.data.store_storage,
          JSON.stringify(store)
        );
      }
    } else {
      let value = [data];
      localStorage.setItem(configDb.data.store_storage, JSON.stringify(value));
    }
  }

  static setFaviouriteIcon(level, floorData = []) {
    if (this.getLocalCookie().length > 0) {
      getFAVPoi({
        poi_ids: this.getLocalCookie(),
        level: level,
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            let svgTarget = Utils.getLevelName(level, floorData);
            let isIphone = 0;
            let whichImage = 0;
            let divideBy = 1;
            if (navigator.userAgent.match(/iPhone/i)) {
              let mapElement = document.querySelector("#map-floor");
              if (mapElement) {
                isIphone = 1;
                whichImage = mapElement.dataset.value;
                if (mapElement.dataset.value) {
                  if (parseInt(mapElement.dataset.value) === 2) {
                    divideBy = 2;
                  } else if (parseInt(mapElement.dataset.value) === 3) {
                    divideBy = 4;
                  }
                }
              }
            }

            for (let i = 0; i < response.data.data.length; i++) {
              if (
                response.data.data[i] &&
                response.data.data[i]["x_cordinate"]
              ) {
                var newItem = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "image"
                );
                /* newItem.setAttribute("x", response.data.data[i]['x_cordinate']);
                                                                 newItem.setAttribute("y", response.data.data[i]['y_cordinate']);*/
                newItem.setAttribute(
                  "x",
                  response.data.data[i]["x_cordinate"] / divideBy -
                    configDb.data.icon_width
                );
                newItem.setAttribute(
                  "y",
                  response.data.data[i]["y_cordinate"] / divideBy -
                    configDb.data.icon_height
                );

                newItem.setAttributeNS(
                  "http://www.w3.org/1999/xlink",
                  "href",
                  favIcon
                );

                if (isIphone == 1) {
                  if (whichImage == 2) {
                    newItem.setAttribute("class", "FavIconType2");
                    newItem.setAttribute(
                      "x",
                      response.data.data[i]["x_cordinate"] / divideBy - 18
                    );
                    newItem.setAttribute(
                      "y",
                      response.data.data[i]["y_cordinate"] / divideBy - 18
                    );
                  } else if (whichImage == 3) {
                    newItem.setAttribute("class", "FavIconType4");
                    newItem.setAttribute(
                      "x",
                      response.data.data[i]["x_cordinate"] / divideBy - 12
                    );
                    newItem.setAttribute(
                      "y",
                      response.data.data[i]["y_cordinate"] / divideBy - 12
                    );
                  } else {
                    newItem.setAttribute("class", "FavIconTypeElse");
                  }
                }
                document.getElementById(svgTarget).appendChild(newItem);
              }
            }
          }
        })
        .catch((err) => {
          //toast.error(Utils.getErrorMessage(err));
        });
    }
  }

  static setAllPOI(
    level,
    aName,
    bName,
    qrType,
    origin,
    isInsideCall,
    floorData = [],
    isOriginHandled = false
  ) {
    getAllPOI({ level: level })
      .then((response) => {
        let isIphone = 0;
        let whichImage = 0;
        if (response.data.data.length > 0) {
          let svgTarget = Utils.getLevelName(level, floorData);

          let divideBy = 1;
          let rSize = 5;
          if (navigator.userAgent.match(/iPhone/i)) {
            let mapElement = document.querySelector("#map-floor");
            if (mapElement) {
              isIphone = 1;
              whichImage = mapElement.dataset.value;
              if (mapElement.dataset.value) {
                if (parseInt(mapElement.dataset.value) === 2) {
                  divideBy = 2;
                  rSize = 3;
                } else if (parseInt(mapElement.dataset.value) === 3) {
                  divideBy = 4;
                  rSize = 2;
                }
              }
            }
          }
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i] && response.data.data[i]["x_cordinate"]) {
              let newItem = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "circle"
              );
              newItem.setAttribute("class", "circleAll");
              newItem.setAttribute("name", response.data.data[i]["name"]);
              newItem.setAttribute(
                "cx",
                response.data.data[i]["x_cordinate"] / divideBy
              );
              newItem.setAttribute(
                "cy",
                response.data.data[i]["y_cordinate"] / divideBy
              );
              newItem.setAttribute("r", rSize);
              newItem.setAttribute("fill", "rgba(29,52,84,0.5)");
              document.getElementById(svgTarget).appendChild(newItem);
            }

            if (response.data.data[i]["name"] === aName) {
              let myTextElement = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "image"
              );
              /*let myText = document.createTextNode("A");*/
              myTextElement.setAttribute(
                "x",
                response.data.data[i]["x_cordinate"] / divideBy - 21.5
              );
              myTextElement.setAttribute(
                "y",
                response.data.data[i]["y_cordinate"] / divideBy - 20.5
              );
              myTextElement.setAttribute("id", "start-point");
              myTextElement.setAttributeNS(
                "http://www.w3.org/1999/xlink",
                "href",
                AIcon
              );
              /*myTextElement.appendChild(myText);*/

              if (isIphone == 1) {
                if (whichImage == 2) {
                  myTextElement.setAttribute("class", "AIconType2");
                  myTextElement.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 18
                  );
                  myTextElement.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 18
                  );
                } else if (whichImage == 3) {
                  myTextElement.setAttribute("class", "AIconType4");
                  myTextElement.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 12
                  );
                  myTextElement.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 12
                  );
                } else {
                  myTextElement.setAttribute("class", "AIconTypeElse");
                }
              }

              document.getElementById(svgTarget).appendChild(myTextElement);
            }

            if (response.data.data[i]["name"] === bName) {
              let myTextElement = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "image"
              );
              myTextElement.setAttribute(
                "x",
                response.data.data[i]["x_cordinate"] / divideBy - 22
              );
              myTextElement.setAttribute(
                "y",
                response.data.data[i]["y_cordinate"] / divideBy - 20
              );
              myTextElement.setAttributeNS(
                "http://www.w3.org/1999/xlink",
                "href",
                BIcon
              );
              myTextElement.setAttribute("id", "start-point");

              if (isIphone == 1) {
                if (whichImage == 2) {
                  myTextElement.setAttribute("class", "BIconType2");
                  myTextElement.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 18
                  );
                  myTextElement.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 14
                  );
                } else if (whichImage == 3) {
                  myTextElement.setAttribute("class", "BIconType4");
                  myTextElement.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 10
                  );
                  myTextElement.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 10
                  );
                } else {
                  myTextElement.setAttribute("class", "BIconTypeElse");
                }
              }

              document.getElementById(svgTarget).appendChild(myTextElement);

              /* Car icon set on end point for qr type 3 code begin */
              if (parseInt(qrType) === 3) {
                let newItem = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "image"
                );
                newItem.setAttribute("id", "carIconEnd");
                newItem.setAttribute("class", "d-none");
                newItem.setAttribute(
                  "x",
                  response.data.data[i]["x_cordinate"] / divideBy -
                    configDb.data.icon_width
                );
                newItem.setAttribute(
                  "y",
                  response.data.data[i]["y_cordinate"] / divideBy -
                    configDb.data.icon_height
                );
                newItem.setAttributeNS(
                  "http://www.w3.org/1999/xlink",
                  "href",
                  carIcon
                );
                document.getElementById(svgTarget).appendChild(newItem);
              }
              /* Car icon set on end point for qr type 3 code end */

              /* Location icon set on end point for qr type 3 code begin */
              if (parseInt(qrType) === 4) {
                let newItem = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "image"
                );
                newItem.setAttribute("id", "locationIconEnd");
                newItem.setAttribute("class", "d-none");
                newItem.setAttribute(
                  "x",
                  response.data.data[i]["x_cordinate"] / divideBy -
                    configDb.data.icon_width
                );
                newItem.setAttribute(
                  "y",
                  response.data.data[i]["y_cordinate"] / divideBy -
                    configDb.data.icon_height
                );
                newItem.setAttributeNS(
                  "http://www.w3.org/1999/xlink",
                  "href",
                  locationIcon
                );
                document.getElementById(svgTarget).appendChild(newItem);
              }
              /* Location icon set on end point for qr type 3 code end */
            }

            let data = localStorage.getItem("origin3");
            let dataQrType = localStorage.getItem("originType3");

            if (
              response.data.data[i]["name"] === origin &&
              parseInt(qrType) === 3 &&
              isInsideCall === false
            ) {
              localStorage.setItem("originType3", qrType);
              localStorage.setItem("origin3", origin);

              localStorage.removeItem("originType4");
              localStorage.removeItem("origin4");

              let newItem = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "image"
              );
              newItem.setAttribute("id", "carIconStart");
              newItem.setAttribute(
                "x",
                response.data.data[i]["x_cordinate"] / divideBy -
                  configDb.data.icon_width
              );
              newItem.setAttribute(
                "y",
                response.data.data[i]["y_cordinate"] / divideBy -
                  configDb.data.icon_height
              );
              newItem.setAttributeNS(
                "http://www.w3.org/1999/xlink",
                "href",
                carIcon
              );

              document.getElementById(svgTarget).appendChild(newItem);

              if (isIphone == 1) {
                if (whichImage == 2) {
                  newItem.setAttribute("class", "carIconType2");
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 18
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 40
                  );
                } else if (whichImage == 3) {
                  newItem.setAttribute("class", "carIconType4");
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 13
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 30
                  );
                } else {
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 25
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 70
                  );
                  newItem.setAttribute("class", "carIconTypeElse");
                }
              }
            }

            if (
              parseInt(dataQrType) === 3 &&
              isInsideCall === true &&
              data &&
              response.data.data[i]["name"] === origin
            ) {
              let newItem = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "image"
              );

              newItem.setAttribute("id", "carIconStart");
              newItem.setAttribute(
                "x",
                response.data.data[i]["x_cordinate"] / divideBy -
                  configDb.data.icon_width
              );
              newItem.setAttribute(
                "y",
                response.data.data[i]["y_cordinate"] / divideBy -
                  configDb.data.icon_height
              );
              newItem.setAttributeNS(
                "http://www.w3.org/1999/xlink",
                "href",
                carIcon
              );

              document.getElementById(svgTarget).appendChild(newItem);

              if (isIphone == 1) {
                if (whichImage == 2) {
                  newItem.setAttribute("class", "carIconType2");
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 18
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 40
                  );
                } else if (whichImage == 3) {
                  newItem.setAttribute("class", "carIconType4");
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 13
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 30
                  );
                } else {
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 25
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 70
                  );
                  newItem.setAttribute("class", "carIconTypeElse");
                }
              }
            }

            let data4 = localStorage.getItem("origin4");
            let dataQrType4 = localStorage.getItem("originType4");

            if (
              response.data.data[i]["name"] === origin &&
              parseInt(qrType) === 4 &&
              isInsideCall === false
            ) {
              localStorage.setItem("originType4", qrType);
              localStorage.setItem("origin4", origin);

              localStorage.removeItem("originType3");
              localStorage.removeItem("origin3");
              let newItem = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "image"
              );
              newItem.setAttribute("id", "locationIconStart");
              newItem.setAttribute(
                "x",
                response.data.data[i]["x_cordinate"] / divideBy -
                  configDb.data.icon_width
              );
              newItem.setAttribute(
                "y",
                response.data.data[i]["y_cordinate"] / divideBy -
                  configDb.data.icon_height
              );
              newItem.setAttributeNS(
                "http://www.w3.org/1999/xlink",
                "href",
                locationIcon
              );
              // if (navigator.userAgent.match(/iPhone/i)) {
              //   newItem.setAttribute("width", "25");
              // }
              document.getElementById(svgTarget).appendChild(newItem);

              if (isIphone == 1) {
                if (whichImage == 2) {
                  newItem.setAttribute("class", "carIconType2");
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 18
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 40
                  );
                } else if (whichImage == 3) {
                  newItem.setAttribute("class", "carIconType4");
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 13
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 30
                  );
                } else {
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 25
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 70
                  );
                  newItem.setAttribute("class", "carIconTypeElse");
                }
              }
            }

            if (
              parseInt(dataQrType4) === 4 &&
              isInsideCall === true &&
              data4 &&
              response.data.data[i]["name"] === origin
            ) {
              let newItem = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "image"
              );
              newItem.setAttribute("id", "locationIconStart");
              newItem.setAttribute(
                "x",
                response.data.data[i]["x_cordinate"] / divideBy -
                  configDb.data.icon_width
              );
              newItem.setAttribute(
                "y",
                response.data.data[i]["y_cordinate"] / divideBy -
                  configDb.data.icon_height
              );
              newItem.setAttributeNS(
                "http://www.w3.org/1999/xlink",
                "href",
                locationIcon
              );
              // if (navigator.userAgent.match(/iPhone/i)) {
              //   newItem.setAttribute("width", "25");
              // }
              document.getElementById(svgTarget).appendChild(newItem);

              if (isIphone == 1) {
                if (whichImage == 2) {
                  newItem.setAttribute("class", "carIconType2");
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 18
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 40
                  );
                } else if (whichImage == 3) {
                  newItem.setAttribute("class", "carIconType4");
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 13
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 30
                  );
                } else {
                  newItem.setAttribute(
                    "x",
                    response.data.data[i]["x_cordinate"] / divideBy - 25
                  );
                  newItem.setAttribute(
                    "y",
                    response.data.data[i]["y_cordinate"] / divideBy - 70
                  );
                  newItem.setAttribute("class", "carIconTypeElse");
                }
              }
            }
          }
        }
      })
      .catch((error) => {});
  }

  static drowRouteDots(imageName = "") {
    setTimeout(function () {
      let allLineClass = document.getElementsByClassName("lineSingh");
      for (let i = 0; i < allLineClass.length; i++) {
        setTimeout(function timer() {
          if (allLineClass[i]) {
            allLineClass[i].classList.remove("d-none");
          }
        }, i * 500);
      }
    }, 0);
  }

  static getFloorImage(floor) {
    return "/images/floors/" + floor + ".svg";
  }

  static detectDevice() {
    if (navigator.userAgent.match(/iPhone/i)) {
      return true;
    } else {
      return false;
    }
    // return true;
  }
}

export default Utils;
